<template>
  <div class="form-group">
     <label for="input11" class="d-block" 
      >Seu Status</label
    >
    <div class="status">
      <div v-if="isAvaliable" class="bg-success online"></div>
      <div v-else class="offline"></div>
      <b-spinner
        v-show="loading"
        variant="seconday"
        small
        label="Spinning"
        class="status_loadding"
      ></b-spinner>

      <b-form-select v-model="selected" :options="options" size="sm">
      </b-form-select>
    </div>
  </div>

  
</template>

<script>
import SocketioService from "../../services/socketio.service";

export default {
  props:["setStatus"],
  data() {
    return {
      //   selected: null,
      // selected: "Indisponível",
      selected: localStorage.getItem("status-operador") || "Indisponível",

      // selected: "DDisponível",
      options: [
        { value: null, text: "Selecione seu status", disabled: true },
        { value: "Disponível", text: "Disponível" },
        { value: "Indisponível", text: "Indisponível" },
        { value: "Pausa Break", text: "Pausa Break" },
        { value: "Ocupado Reunião", text: "Ocupado Reunião" },
        { value: "Pausa ausente", text: "Pausa ausente" },
        { value: "Pausa treinamento", text: "Pausa Treinamento" },
      ],
      loading: false,
      isAvaliable: false,
      user: undefined,
    };
  },
  methods: {
    async addUser() {
      this.isAvaliable = false;
      this.loading = true;

      localStorage.setItem("status-operador", this.selected);

      SocketioService.socket.emit("add-user", {
        usuario: this.user.nome,
        id: this.user.id,
        status: this.selected,
      });

      this.loading = false;

      if (this.selected === "Disponível") this.isAvaliable = true;
      else this.isAvaliable = false;
    },

    

    updateStatus() {
      localStorage.setItem("status-operador", this.selected);


      SocketioService.socket.emit("change-status", {
        usuario: this.user.nome,
        id: this.user.id,
        status: this.selected,
      });
      if (this.selected === "Disponível") this.isAvaliable = true;
      else this.isAvaliable = false;
    },
  },
  watch: {
    selected() {
      this.setStatus(this.selected);
      this.updateStatus();
    },
  },
  mounted() {
    this.user = this.$store.state.auth.user;

    this.addUser();



  },

  // created() {
  //   SocketioService.setupSocketConnection();
  // },
  // beforeDestroy() {
  //   SocketioService.disconnect();
  // },

  destroyed() {},
  
};
</script>

<style lang="scss" scoped>
.status {
  position: relative;
  display: flex;
  align-items: center;

  .status_loadding {
    position: absolute;
    right: 5px;
  }
}
.online {
  width: 10px;
  height: 10px;
  position: absolute;

  left: 7px;
  border-radius: 50%;
}
.offline {
  @extend .online;
  border: 2px solid;
  background-color: #ccc;
}

.custom-select-sm {
  padding-left: 20px;
}
.custom-select {
}
</style>


<style lang="scss">
</style>