<template>
  <div
    class="col-xl-3 col-lg-12 col-md-4 chat-list-wrapper px-0 conversationList"
  >
    <div class="conversationList__new__conversation">
      <button class="btn btn-primary " :disabled="!conversationsClient" v-b-modal.modal-whatsapp-new-conversation>+ Nova Conversa</button>
    </div>
    

    <div class="text-center mt-5" v-if="conversations.length == 0">
      <span class="text-muted">Não há conversas a serem exibidas</span>

      <!-- <b-spinner small label="Small Spinner" type="grow"></b-spinner> -->
    </div>

    <div class="chat-list-item-wrapper pt-0">
      <div
        class="list-item d-flex"
        :class="item.sid === selectedConversationSid ? 'chat__selected' : ''"
        v-for="item in conversations"
        :key="item.sid"
        @click="setConversation(item)"
      >
        <div class="w-100">
          <p class="chat-text">
            {{ item.friendlyName || item.sid }}
          </p>

          <p class="user-name">
            <LastMessage :lastMessage="item" />
          </p>
        </div>
      </div>
    </div>

    <!-- <WhatsAppNewConversationVue/> -->

    <!-- <p v-for="(item, index) in testeAa" :key="index" >lalala</p> -->
  </div>
</template>

<script>
import LastMessage from "./LastMessage.vue";
import conversationState from "@/store/module-observable/conversation/index";




export default {
  components: { LastMessage,  },
  props: [
    "conversations",
    "selectedConversationSid",
    "onConversationClick",
    "conversationsClient"
  ],
  data() {
    return {
      itemSelected: undefined,
    };
  },

  mounted() {},

  updated() {
    // console.log("update here");
    //MUDAR ISSO PRA WATCH
    // if (this.itemSelected) {
    //   if (this.itemSelected.sid === this.selectedConversationSid) {
    //     this.setCurrentConversation.isCurrentConversation = this.itemSelected
    //     this.setReadConversation(this.itemSelected);
    //   }
    // }
  },
  methods: {
    setConversation(item) {
      this.onConversationClick(item);
      this.setReadConversation(item);

      this.itemSelected = item;
      // console.log(item);
      // item.updateLastReadMessageIndex(10)
    },
    setReadConversation(conversation) {
      // console.log();
      const index = conversation.lastMessage.index;
      conversation.updateLastReadMessageIndex(index);
    },
  },
  computed: {
    setCurrentConversation() {
      return conversationState;
    },
  },
  watch: {
    conversations() {
      // console.log(this.conversations);

      this.$nextTick(function () {
        // console.log("update here conversations depois de atualizado");

        if (this.itemSelected) {
          if (this.itemSelected.sid === this.selectedConversationSid) {
            this.setCurrentConversation.isCurrentConversation =
              this.itemSelected;

            this.setReadConversation(this.itemSelected);
          }
        }
      });
    },
    itemSelected() {
      // console.log("itemSelected ", this.itemSelected);
    },
  },
};
</script>

<style scoped lang="scss">
.conversationList {
  .chat__selected {
    background: rgba(0, 144, 231, 0.1);
  }
  .conversationList__new__conversation{
    /* background-color: red; */
    border-bottom : 1px solid #2c2e33;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    button{
      width: 90%;
    }
  }
}
</style>