import axios from 'axios';
import { io } from 'socket.io-client';


class SocketioService {
    socket;
    constructor() {}

    setupSocketConnection() {
        this.socket = io("https://socket-clients-online.herokuapp.com/");
    }


    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    ping() {
        return axios.get("https://socket-clients-online.herokuapp.com/")
    }
}



export default new SocketioService();