import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/projetos";
const API_URL_BASE = process.env.VUE_APP_API_URL;

class ProjetoService {
  getAll() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  get(id) {
    return axios.get(API_URL + "/" + id, { headers: authHeader() });
  }

  addProject(params) {
    return axios.post(
      API_URL,
      {
        nome: params.name,
        descricao: params.descricao,
        dt_inicio: params.dateFomatedStart,
        dt_fim: params.dateFomatedEnd,
        meta: params.meta,
        projeto_tipo_id: params.tipo === "Base de Dados" ? 1 : 2,
        // projeto_tipo_id: 3,
        cliente_id: params.cliente.name.id,
      },
      {
        headers: authHeader(),
      }
    );
  }

  editProjet(params, editItem, idClient) {
    return axios.put(
      API_URL + "/" + editItem.id,
      {
        nome: params.name,
        descricao: params.descricao,
        dt_inicio: params.dateFomatedStart,
        dt_fim: params.dateFomatedEnd,
        meta: params.meta,
        cliente_id: idClient,
        projeto_situacao_id: editItem.projeto_situacao_id,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deleteProject(id) {
    return axios.delete(API_URL + "/" + id, { headers: authHeader() });
  }

  changeStatus(params) {
    return axios.put(
      API_URL + "/" + params.id,
      {
        nome: params.nome,
        descricao: params.descricao,
        dt_inicio: params.dt_inicio,
        dt_fim: params.dt_fim,
        meta: params.meta,
        cliente_id: params.cliente_id,
        // projeto_situacao_id: params.projeto_situacao_id == 1 ? 2 : 3
        projeto_situacao_id:
          params.projeto_situacao_id == 1
            ? 2
            : params.projeto_situacao_id == 2
            ? 3
            : "",
      },
      {
        headers: authHeader(),
      }
    );
  }

  getInfProject(id) {
    return axios.get(`${API_URL}/${id}`, { headers: authHeader() });
  }

  getInfDashboard(id) {
    return axios.get(`${API_URL}/${id}/dashboard`, { headers: authHeader() });
  }

  getInfDashboardFilter(id, inicio, fim) {
    return axios.get(`${API_URL}/${id}/dashboard?inicio=${inicio}&fim=${fim}`, {
      headers: authHeader(),
    });
    // return axios.get(`${API_URL}/85/dashboard?inicio=2021-01-01&fim=2021-12-31&periodo=semana`, { headers: authHeader() })
  }
  getInfDashboardFilterByVision(id, vision) {
    return axios.get(
      `${API_URL}/${id}/dashboard?inicio=&fim=&periodo=${vision}`,
      { headers: authHeader() }
    );
  }

  // Listar status do projeto---------------------

  getListStatus(id) {
    return axios.get(`${API_URL_BASE}/leadStatus`, {
      headers: authHeader(),

      params: { projeto_id: id },
    });
  }

  addStatus(status, id, classification) {
   // console.log(status + id);
   //console.log(classification);

    let classificationID;

    if (classification === "Sucesso") {
      classificationID = 1;
    }
    if (classification === "Falha") {
      classificationID = 2;
    }
    if (classification === "Em andamento") {
      classificationID = 3;
    }

    //console.log(classificationID);

    return axios.post(
      `${API_URL_BASE}/leadStatus`,
      {
        projeto_id: id,
        nome: status,
        lead_status_tipo_id: classificationID,
        
      },
      {
        headers: authHeader(),
      }
    );
  }

  deleteStatus(id) {
    return axios.delete(`${API_URL_BASE}/leadStatus/${id}`, {
      headers: authHeader(),
    });
  }

  // Listar Motivos de Recusa do Projeto---------------------

  getListRecusas(id) {
    return axios.get(`${API_URL_BASE}/leadRecusas`, {
      headers: authHeader(),
      params: { projeto_id: id },
    });
  }

  addRecusas(status, id) {
    return axios.post(
      `${API_URL_BASE}/leadRecusas`,
      {
        projeto_id: id,
        nome: status,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deleteRecusas(id) {
    return axios.delete(`${API_URL_BASE}/leadRecusas/${id}`, {
      headers: authHeader(),
    });
  }

  // Arquivos do Projeto

  getArquivos(projetoId) {
    return axios.get(API_URL + "/" + projetoId + "/arquivos", {
      headers: authHeader(),
    });
  }

  uploadArquivo(projetoId, params) {
    return axios.post(API_URL + "/" + projetoId + "/arquivos", params, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteArquivo(projetoId, id) {
    return axios.delete(API_URL + "/" + projetoId + "/arquivos/" + id, {
      headers: authHeader(),
    });
  }

  downloadArquivo(projetoId, id) {
    return axios.get(
      API_URL + "/" + projetoId + "/arquivos/" + id + "/download",
      {
        responseType: "arraybuffer",
        headers: authHeader(),
      }
    );
  }
}

export default new ProjetoService();
