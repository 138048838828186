import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/leadAtividades";


class LeadAtividades { 
    getAll(params) {
        return axios.get(API_URL, {
            params: params,
            headers: authHeader() 
        });
    }

    save(data){
        return axios.post(API_URL,
            data,
            { headers: authHeader() });      

    }



   
}

export default new LeadAtividades();
