<template>
  <div class="hover__items--menu" id="chat">
    <button class="btn" @click="showChat">
      <!-- <i  class="mdi mdi mdi-email"></i> -->
      <div class="count-indicator">
      <i class="mdi mdi-whatsapp" style="font-size: 1.2rem;"></i><span class="count bg-success"></span>



        <!-- <i class="mdi mdi-email"></i><span class="count bg-success"></span> -->

        

        <span class="text-success indicator__count" v-if="conversations.length > 0">{{conversations.length}}</span>

        
      </div>
    </button>

    <button class="btn__close btn btn-danger" v-show="show" @click="showChat">
      X
    </button>

    <!-- <div v-if="show" class="chat__container"> -->
    <div v-show="show" class="chat__container">
      <section class="chat">
        <div class="row h-100">
          <div class="col-md-12 h-100">
            <div class="card chat-app-wrapper">
              <b-card header-tag="header" no-body>
                <template #header>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <h6 class="mb-0 flex-1">WhatsApp </h6>

                      <b-button
                      variant="btn"
                        class=" ml-2"
                        @click="handleSoundNotify"
                        v-b-tooltip.hover :title="activedSound ? 'Notificações sonoras ATIVADA': 'Notificações sonoras DESATIVADA'"
                      >
                        <i class="mdi mdi-bell" v-if="activedSound"  ></i>
                        <i class="mdi mdi-bell-off" v-else ></i>


                      </b-button>
                    </div>

                    <div>
                      <h4  class="m-0  text-center " :class="statusString == 'Conectado' ? 'text-success': 'text-muted'" >{{ statusString }}</h4>
                      <!-- <h4 v-else class="m-0  text-center text-muted">{{ statusString }}</h4> -->
                      

                    </div>
                    <!-- <h2>Ola</h2> -->

                    <div class="d-flex">
                      <!-- <input
                        type="text"
                        class="form-control mr-1"
                        v-model="usernameChat"
                        style="width: 100px"
                      /> -->

                       <b-form-select
                          v-model="selected"
                        
                          :options="options"
                          :disabled="!!conversationsClient"
                          size="sm"
                        ></b-form-select>


                      <button  class="btn btn-primary ml-2 d-flex align-items-center" v-if="!conversationsClient" @click="setUsernameChat"
                        :disabled="!selected || loading"
                      >

                       <template v-if="loading">
                          <b-spinner small type="grow"></b-spinner>
                          Entrando...
                        </template>
                        <template v-else> Entrar </template>






                        
                      </button>
                      <button class="btn btn-danger ml-2" v-else @click="logout">
                        Sair
                      </button>
                    </div>
                  </div>
                </template>
              </b-card>

              <!-- <h1>ca</h1> -->
              <div
                class="row mx-0 h-100 flex-nowrap"
                style="height: calc(100% - 63px) !important"
              >
                <!-- <h4 class="m-2">{{statusString}}</h4> -->

                

                <ConversationsList
                  :conversations="conversations"
                  :selectedConversationSid="selectedConversationSid"
                  :onConversationClick="onConversationClick"
                  :conversationsClient="conversationsClient"
                />

                <ConversationView
                  :conversationProxy="selectedConversation"
                  :resetSelectedConversation="resetSelectedConversation"
                  :usernameChat="usernameChat"
                />

                <div v-if="!selectedConversation" class="w-100 d-flex align-items-center justify-content-center"> 
                  <h5 class="text-muted" v-if="conversations.length > 0">Selecione uma conversa ao lado para começar</h5>
                  <h5 class="text-muted" v-if="conversations.length == 0">Não há mensagens a serem exibidas</h5>

                </div>                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

   <!-- <h1>before</h1> -->

    

    <WhatsAppNewConversation :infoProject="selected"/>

  </div>
</template>

<script>
import axios from "axios";
import { Client } from "@twilio/conversations";
import ConversationsList from "./components/ConversationsList.vue";
import ConversationView from "./components/ConversationView.vue";
// import { mapMutations } from 'vuex';
import conversationState from "@/store/module-observable/conversation/index";
import TwilioService from '../../services/twilio.service';
import WhatsAppNewConversation from './components/WhatsAppNewConversation.vue';

import observableStateChat from '@/store/module-observable/conversation';


// WhatsAppNewConversation

export default {
  components: { ConversationsList, ConversationView,WhatsAppNewConversation },

  data() {
    return {
      show: false,
      usernameChat: "",

      conversationsClient: undefined,
      statusString: "Inativo",
      conversations: [],
      selectedConversationSid: null,
      selectedConversation: undefined,
      activedSound: true,
      // options: [],
      options: [

        // { value: null, text: 'Selecione um número:' },
        // { value: { telefone: '551132303906', description: "Projeto PIATTINO" }, text: 'Piattino +551132303906' },
        // { value: { telefone: '551150392240', description: "Projeto NUTANIX" }, text: 'Nutanix +551150392240' },
        // { value: { telefone: '551150392267', description: "Projeto RED HAT" }, text: 'Red Hat +551150392267' },
        // { value: { telefone: '551150392279', description: "Projeto UIPATH" }, text: 'UiPath +551150392279' },
        // { value: { telefone: '551150391806', description: "Projeto PIATTINO HEALTH" }, text: 'Piattino Health +551150391806' },
        // { value: { telefone: '551150392021', description: "Projeto QAD" }, text: 'QAD +551150392021' },
        // { value: { telefone: '5511998009970', description: "Projeto Developer" }, text: 'DEVELOPER +5511998009970' },
        // { value: { telefone: '14155238886', description: "Projeto TWILIO" }, text: 'TWILIO +14155238886' },
        





      ],

      selected:null,
      loading: false,

    };
  },
  methods: {
    // ...mapMutations(["setListAllMessages"]),

    showChat() {
      this.show = !this.show;
    },
    async getToken() {      
      this.conversations = []

      


      // this.usernameChat = localStorage.getItem("usernameChat") || "PIATTINO";
      this.usernameChat = localStorage.getItem("usernameChat");
      if(!this.usernameChat) return


      this.loading = true



      try {
        const res = await axios.post(
          "https://orchid-woodpecker-7617.twil.io/token-chat",
          {
            // identity: this.$store.state.auth.user.email,
            // identity: "HEALTH",
            identity: this.usernameChat,
          }
        );

        // console.log(res.data);
        const token = res.data;
        this.loading = false

        // console.log(token);
        this.initConversations(token);
      } catch (error) {
        console.error(error);
        this.loading = false

      }
    },

    async initConversations(token) {
      this.conversationsClient = new Client(token);
      this.addDeviceListeners();
    },

    addDeviceListeners() {
      this.conversationsClient.on("connectionStateChanged", (state) => {
        // console.log(state);

        if (state === "connecting")
          this.statusString = "Conectando ao Twilio……";

        if (state === "connected") {
          this.statusString = "Conectado";
        }
        if (state === "disconnecting")
          this.statusString = "Desconectando do Twilio…";

        if (state === "disconnected") this.statusString = "disconnected";

        if (state === "denied") this.statusString = "Falhou ao conectar.";
        // ----------------
      });

      this.conversationsClient.on("messageAdded", (state) => {
        // console.log("messageAdded ", state);
        // console.log("messageAdded");
        this.notifyNewMessage(state);
      });

      // this.conversationsClient.on("messageUpdated", (state) => {
      //   console.log("*** messageUpdated ", state);

      // });

      this.conversationsClient.on("conversationJoined", (conversation) => {
        // console.log("conversationJoined ", conversation);
        this.conversations = [...this.conversations, conversation];
        this.orderArrayByLastTime();
      });

      this.conversationsClient.on("conversationLeft", (thisConversation) => {
        //Disparado quando o cliente sai de uma conversa.
        // console.log("conversationLeft ", thisConversation);
        this.conversations = [
          ...this.conversations.filter((it) => it !== thisConversation),
        ];
      });

      this.conversationsClient.on("conversationAdded", async (state) => {
        // console.log("conversationAdded ", state);
        this.notifyNewConversation(state);
       
      });

      this.conversationsClient.on("conversationRemoved", async () => {
        console.log("conversationRemoved");
      });

      this.conversationsClient.on(
        "conversationUpdated",
        async () => {
          // console.log("conversationUpdated ", conversation);
          // const client = this.conversationsClient
          // const subscribedConversations = await client.getSubscribedConversations();
          const subscribedConversations =
            await this.conversationsClient.getSubscribedConversations();
          

          this.conversations = subscribedConversations.items;
          this.listMessages.listAllMessages = subscribedConversations.items;

          this.orderArrayByLastTime();
        }
      );

      this.conversationsClient.on("tokenExpired", async () => {
        // console.log("tokenExpired");
        this.getToken();
      });
    },
    onConversationClick(item) {
      
      this.selectedConversationSid = item.sid;
    },
    resetSelectedConversation() {
      // alert(123)
      this.selectedConversation = undefined;
      this.selectedConversationSid = null
    },
    makeToast(variant = null, state) {
      // console.log(state);

      this.$bvToast.toast(state.body || "Mensagem de mídia", {
        title: `Nova mensagem | ${state.author}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-left"

      });
    },

    setUsernameChat() {
      // console.log(this.selected);

      
      localStorage.setItem("selected-number", JSON.stringify( this.selected));
      this.usernameChat = `whatsapp:+${this.selected.telefone}`
      // this.usernameChat = `whatsapp:+14155238886`
      // console.log(this.usernameChat);


      
      localStorage.setItem("usernameChat", this.usernameChat);
      this.selectedConversation = undefined;
      this.selectedConversationSid = null


      //global state
      this.currentProject.currentProjectInfo.telefone = this.selected


      
      this.getToken();

      
      

    },
    orderArrayByLastTime() {
      // console.log("orderArray chamado");
      this.conversations = this.conversations.sort((a, b) => {
        return (
          (b.lastMessage?.dateCreated || b.dateUpdated) -
          (a.lastMessage?.dateCreated || a.dateUpdated)
        );
      });
      this.listMessages.listAllMessages = this.conversations;
    },
    notifyNewMessage(state) {
      // console.log("notify");
      if (this.show) return;
      if (state.author != this.usernameChat) {
        const audio = new Audio(
          "https://ringtones-mp3.com/mobile-rington/_ld/29/2973_call-sound.ru__.mp3"
        );
        if (this.activedSound) {
          audio.play();
        }
        this.makeToast("primary", state);
      }
    },
    notifyNewConversation(state) {
      const audio = new Audio(
        "https://ringtonazer.b-cdn.net/fetch/94/94fd38c23836784b3db474d831ee436d.mp3"
      );
      if (this.activedSound) {
        audio.play();
      }
      // audio.play();

      this.$bvToast.toast(state.friendlyName || "", {
        title: `Nova Conversa adicionada`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-left"

      });
    },
    handleSoundNotify() {
      this.activedSound = !this.activedSound;
      localStorage.setItem("activedSound", this.activedSound);
    },
    
    getNumbersTwilio() {
      
      TwilioService.getNumbersTwilio()
      .then((res) => {
        // console.log(res.data);
        this.options = res.data
      }).catch((err) => {
        console.log(err);
      })
    },
    async logout(){
      try {
        await this.conversationsClient.shutdown()
        //  console.log(res);
          this.conversations = []
          this.selectedConversationSid = null
          this.selectedConversation = undefined
          this.conversationsClient = undefined
          localStorage.removeItem("usernameChat")
          // this.conversationsClient?.removeAllListeners();


        //  console.log(res)
      } catch (error) {
        console.error(error);
      
        
      }

      

    }
  },

  mounted() {
    const isActiveSound = localStorage.getItem("activedSound");
    if (isActiveSound) {
      this.activedSound = JSON.parse(isActiveSound);
    }
    // console.log(this.activedSound );
    this.selected =  JSON.parse( localStorage.getItem("selected-number"))
    // this.selected.telefone =  localStorage.getItem("selected-number")

    
    this.currentProject.currentProjectInfo.telefone = this.selected

  

    
    this.getToken();
    this.getNumbersTwilio()
      

  },
  updated() {},

  beforeDestroy() {
    // console.log("vai destruit 12");
    this.conversationsClient?.removeAllListeners();
  },
  watch: {
    selectedConversationSid() {
      // console.log(this.selectedConversationSid);
      const selectedConversationSelected = this.conversations.find(
        (it) => it.sid === this.selectedConversationSid
      );

      // console.log("filtrado eh ",selectedConversation);

      this.selectedConversation = selectedConversationSelected;

      // console.log("filtrado eh ", this.selectedConversation);

      // console.log("last message ", this.selectedConversation.lastMessage);

      // this.selectedConversation.lastMessage()
      // .then(res=>{
      //   console.log(res);
      // })
    },
    selected(){
      // console.log("selected ", this.selected);
    },
    conversationsClient() {
      // console.log("conversationsClient");
      // console.log(this.conversationsClient);
    },
    // conversations(){
    //   // console.log("conversations 000");
    //   this.orderArray()
    // }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      return this.$store.getters["auth/hasRole"]("admin");
    },
    listMessages() {
      return conversationState;
    },
    currentProject(){
      return observableStateChat
    }


    
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.chat__container {
  background: #000000d4;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-app-wrapper {
  height: 90vh;

  width: 100;
}

.chat {
  width: 90%;
  height: 90%;
}

.btn__close {
  position: fixed;
  right: 20px;
  z-index: 101;
}
.indicator__count{
      position: absolute;
    bottom: 13px;
    font-size: 10px;
  
}

.custom-select:disabled{
  background: transparent;
}


</style>



<style lang="scss">
#chat{

  .card{
    background-color: #191c24 !important;
    border: 0;
  }

  textarea{
    background-color: transparent;
    color: white;
  }
  label{
    color: white;
  }
}
</style>