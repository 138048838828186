<template  >
  <b-modal
    id="chooseProject"
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    :content-class="
      !this.$store.state.showWhiteMode
        ? 'modallEditarStatus'
        : 'modallEditarStatusWhite'
    "
    hide-footer
    scrollable
  >
    <!-- body-bg-variant="light" -->

    <template #modal-title>
      Selecionar Projeto
      <span class="text-primary font-weight-bold text-uppercase"></span>
    </template>

    <div>
      <div class="add-items d-flex">
        <!-- <b-button v-else @click="setEditStatus" variant="outline-secondary" class="add todo-list-add-btn" id="add-task">Alterar</b-button> -->
      </div>

      <div class="list-wrapper">
        <!-- <ul class="d-flex flex-column-reverse todo-list">
            ssss         
        </ul> -->

        <!-- ---------------------------------------------------------------------------------- -->

        <b-list-group>
          <b-list-group-item
            class="card text-white select__project"
            href="#"
            v-for="projeto in projetos"
            :key="projeto.id"
            @click="setProject(projeto)"
            >{{ projeto.nome }}</b-list-group-item
          >
        </b-list-group>
      </div>
    </div>

    <div
      v-if="projetos.length === 0"
      class="d-flex justify-content-center mt-5 pt-3"
    >
      <h5 class="text-gray">Nenhum projeto encontrado</h5>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// import ProjetoService from "../services/projeto.service";

import ProjetoService from "../../services/projeto.service";

export default {
  name: "todo-list",
  data() {
    return {
      projetos: [],
    };
  },

  computed: {
    ...mapState(["projetoSelecionado", "listenerChangeProjectList"]),
  },
  mounted() {
    this.getListAllProjects();
  },
  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),
    ...mapMutations(["atualizarListenerChangeProjectList"]),
    setProject(projeto) {
      // console.log(projeto);
      this.atualizarProjetoSelecionadoAction(projeto);
      this.$bvModal.hide("chooseProject");
      this.atualizarListenerChangeProjectList();
    },

    getListAllProjects() {
      ProjetoService.getAll({ projeto_tipo_id: 2 }).then((response) => {
        this.projetos = response.data;
      });
    },
  },

  watch: {
    listenerChangeProjectList() {
      this.getListAllProjects();
      //   this.atualizarProjetoSelecionadoAction()
    },
  },
};
</script>

<style lang="scss">
.modallEditarStatus {
  background: #191c24 !important;
  min-height: 370px;
  min-height: auto !important;
}

.modallEditarStatusWhite {
  background: #ffffff;
  border: 1px solid #e4e9f0 !important;
  /* @extend .bg-light; */

  .modal-title {
    color: rgb(0, 0, 0) !important;
  }

  .modal-header {
    background: #f4f4f6 !important;
    border-bottom: 2px solid #e1e3ea !important;
    border-top: 2px solid #e1e3ea !important;
  }

  .select__project {
    background: none;
    border-color: rgb(225, 227, 234) !important;
    color: #121c2d !important;
    border-radius: 0;
  }

  .modal-header .close {
    color: #000 !important;
  }
  .modal-content {
  }
  .list-wrapper ul li {
    /* border-bottom: 1px solid $colorBorder; */
    border-bottom: 1px solid #e4e9f0;
  }
  .form-control {
    border: 1px solid #e4e9f0 !important;
    color: #000;
    background: #ffffff;
  }
  .custom-select {
    background-color: #ffffff;
    border: 1px solid #e4e9f0 !important;
    color: #000 !important;
  }

  .multiselect__tags {
    background: #ffffff;
    border: 0;
    outline: 1px solid #e4e9f0;
  }

  .multiselect__content-wrapper,/// here
  select.form-control,
  select.asColorPicker-input,
  .dataTables_wrapper select,
  .custom-datatable .dataTables_wrapper select,
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .select2-container--default select.select2-selection--single,
  .select2-container--default .select2-selection--single select.select2-search__field,
  select.typeahead,
  select.tt-query,
  select.tt-hint,
  .custom-select {
    border: 1px solid #ced4da;
    outline: 1px solid #e4e9f0;

    color: black;
    background-color: #ffffff;
  }

  .multiselect__input {
    background: #ffffff;
    color: #495057;

    &::placeholder {
      color: rgb(0, 0, 0);
    }
  }
  .multiselect__spinner {
    background: white;
  }

  .multiselect__single {
    background: #fff;
    color: #495057;
  }
}

#my-modal {
  /* max-height: 70vh;
    
    overflow-y: scroll; */

  .headerModal {
  }
  .custom-select {
    color: #6c7293;
    color: #adadad;
  }
}

/* #my-modal:hover #my-modal {
  display: none;
} */
</style>

<style scoped lang="scss" >
/* .my-btn{
    position: absolute;
    bottom: 15px;
    right: 15px;
  } */

.add-items {
  align-items: center;

  select {
    min-height: 37px;
    width: 150px;
    margin-left: 10px;
  }
  button {
    min-height: 37px;
  }
}

.select__project {
  border: 1px solid #2c2e33;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    text-decoration: underline;
  }

  &:active {
    background: rgba(255, 255, 255, 0) !important;
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>


