<template>
  <section class="app-footer">
    <footer class="footer container">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 <a href="https://piattino.digital/" target="_blank">Piattino </a>.</span>
        <span class="text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Fale Conosco: <a href="tel:+551125005960" target="_blank"> (11) 2500-5960 </a></span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>
