<template>
  <div
    class="d-flex"
    :class="message.author === usernameChat ? 'justify-content-end ' : ' '"
  >
    <div
      class="position-relative image"
      :class="message.author === usernameChat ? '' : 'ml-0'"
    >
      <!-- <p>audio</p> -->

      <div :class="message.author === usernameChat ? 'my__user__name ' : 'another__user__name'">

        <audio controls preload>
                  <source v-if="url" :src="url" type="audio/mpeg" />
                  <!-- <source src="https://media.us1.twilio.com/MEa07f81a3845a719d4f85030079b6d3e0?Expires=1654102897&Signature=JbwJ6zFVeIVB0QXCrCIMzId7nL8CmsLhd07P5vZnCpCwbVp6EgkmdzEl8k70A7PuSb93Agf-Hn7IO-LodLZKcfoL6R~HaC2kjVziQjjkNMNlB-buxTiTWpU42vKdk6iViG7GJ7DuBygpPsxvTNCwEiqailpBKKPs42RxD2K1MdcgwVuav41ouFta8pkrQUM8ggK~-kYwn~2rHjQ6M~T4SO7D20ho8ywxJFpK3UmyIHEE6QfJaWG-5eyUBJ6gVnMDdClhy~8R8BGm88ZFw4zUr4XwGDKyJ~hdBo~cV4nLCvNPCKD7qVPSQKEjCTVePb-avVOKmqLwZ9ajUuk1gsgoCw__&Key-Pair-Id=APKAJWF6YVTMIIYOF3AA" type="audio/mpeg" /> -->
        </audio>


        
        <!-- <img :src="url" alt="a" class="w-100 rounded"  > -->
        <!-- <b-img :src="url" fluid alt="Fluid image" class="w-100"></b-img> -->
        <!-- <b-img-lazy  :src="getFileUrl()" alt="Image 1" class="w-100"></b-img-lazy> -->
      </div>

          
         
          

      <span class="conversation__view__hours">{{
        message.state.timestamp.toLocaleString()
      }}</span>
    </div>

    <!-- <h1>{{usernameChat}}</h1> -->
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      usernameChat: undefined,
      url: undefined,
    };
  },
  mounted() {
    this.getUserName();
    this.getFileUrl();
  },
  methods: {
    getUserName() {
      this.usernameChat = localStorage.getItem("usernameChat") || "PIATTINO";
    },
    async getFileUrl() {
      // console.log("media ", this.message.media);
      const media = this.message.media;
      const res = await media.getContentTemporaryUrl();

      // console.log(res);
      this.url = res; 
    },
  },
};
</script>

<style lang="scss" scoped>
.image{
  width: auto;
}
</style>