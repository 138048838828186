const initialState = {
  showModalQualificacao: false,
  showModalNovaOportunidade: false,
  idLead: null,
  contatoData: undefined,
};

export const leads = {
  state: initialState,
  mutations: {
    setShowModalQualificacao(state, value) {
      state.showModalQualificacao = value;
    },
    setShowModalNovaOportunidade(state, value) {
      state.showModalNovaOportunidade = value;
    },
    setIdLead(state, value) {
      state.idLead = value;
    },
    setContatoData(state, value) {
      state.contatoData = value;
    },

  },

  actions: {}
};
