<template>
  <span class="status__message">
    <span title="mensagem não enviada">
      <FailIcon
        v-if="statusMessage === 'undelivered' || statusMessage === 'failed'"
      />
    </span>

    <QueueddIcon v-if="statusMessage === 'queued'" />

    <SendIcon v-if="statusMessage === 'sent'" />
    <DeliveredIcon v-if="statusMessage === 'delivered'" />
    <DeliveredIcon style="color: #53bdeb" v-if="statusMessage === 'read'" />

    <!-- <button @click="getStatusMessage">aaa</button> -->
  </span>
</template> 

<script>
import SendIcon from "./SendIcon.vue";
import FailIcon from "./FailIcon.vue";
import QueueddIcon from "./QueueddIcon.vue";
import DeliveredIcon from "./DeliveredIcon.vue";

import conversationState from "@/store/module-observable/conversation/index";

export default {
  props: ["message"],
  components: {
    SendIcon,
    FailIcon,
    QueueddIcon,
    DeliveredIcon,
  },
  data() {
    return {
      statusMessage: undefined,
    };
  },
  mounted() {
    this.getStatusMessage();
  },
  methods: {
    async getStatusMessage() {
      const message = this.message;
      //   console.log(message);
      const data = await message.getDetailedDeliveryReceipts();

      if (data.length > 0) {
        this.statusMessage = data[0].status;
      }

    //   console.log(this.statusMessage);
    },
  },
  computed: {
    updateMessages() {
      return conversationState.updateMessages;
    },
  },
  watch: {
    updateMessages() {
      this.getStatusMessage();
    },
  },
};
</script>

<style scoped lang="scss">
.status__message {
  position: absolute;
  right: 2px;
  bottom: 0;
}
</style>