<template>
  <div
    class="px-0 d-flex flex-column h-100 conversation__view"
    v-if="conversationProxy"
  >
  
    <!-- <header class="p-3 d-flex justify-content-between">
      <h5 class="m-0" v-if="conversationProxy">
        {{ conversationProxy.friendlyName || conversationProxy.sid }}
      </h5>

      



      <div>
        <span class="text-muted">{{conversationProxy.participants.size}} Participantes </span>

        <b-dropdown size="sm" dropleft   variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <i data-v-14bb84ae="" class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item @click="updateConversationName" > <i class="mdi mdi-lead-pencil"></i> Editar nome da conversa</b-dropdown-item>
        <b-dropdown-item @click="deleteConversation(conversationProxy)" ><i class="mdi mdi-delete text-danger"></i> Encerrar conversa</b-dropdown-item>
      
      </b-dropdown>

      </div>

      




    </header> -->

    <Header :conversationProxy="conversationProxy" :resetSelectedConversation="resetSelectedConversation"/>

    <div class="chat-container-wrapper" ref="chat-container">
      <div
        v-for="message in messages"
        :key="message.sid"
        class="chat-bubble incoming-chat position-relative"
        :class="
          message.author === usernameChat ? 'outgoing-chat ' : 'incoming-chat '
        "
      >



          <!-- message.author === author -->
        <div :class="message.author === author ? 'text-right' : ''">
          <!-- <span class="text-muted">{{message.author}}</span> -->
          <span v-if="message.author != author" class="text-muted">{{message.author}}</span>
          
        </div>





        <MessageTypeText v-if="message.type === 'text'" :message="message" />
        <MessageTypeImage
          v-if="
            message.type === 'media' &&
            message.media.contentType === 'image/jpeg'
          "
          :message="message"
        />
        <MessageTypeImage
          v-if="
            message.type === 'media' &&
            message.media.contentType === 'image/png'
          "
          :message="message"
        />
        <MessageTypeVideo
          v-if="
            message.type === 'media' &&
            message.media.contentType === 'video/mp4'
          "
          :message="message"
        />
        <MessageTypeAudio
          v-if="
            message.type === 'media' &&
            message.media.contentType === 'audio/ogg'
          "
          :message="message"
        />

        <MessageTypePDF
          v-if="
            message.type === 'media' &&
            message.media.contentType === 'application/pdf'
          "
          :message="message"
        />

        <StatusMessageSend
          v-if="message.author === author"
          :message="message"
        />






      </div>
    </div>
    <div class="chat-text-field mt-auto card">
      <form @submit.prevent="sendMessage">
        
        <VEmojiPicker
          v-if="showEmoji"
          @select="selectEmoji"
          class="VEmojiPicker"
        />

        <div class="input-group">
          <div class="input-group-prepend">
            <button
              v-if="!showEmoji"
              type="button"
              class="input-group-text"
              @click="showEmoji = !showEmoji"
            >
              <i class="mdi mdi-emoticon-happy-outline icon-sm"></i>
            </button>
            <button
              v-else
              type="button"
              class="input-group-text"
              @click="showEmoji = !showEmoji"
            >
              X
            </button>

            <b-button
              v-b-modal.modal-file-1
              type="button"
              class="input-group-text"
            >
              <i class="mdi mdi-paperclip icon-sm"></i>
            </b-button>
          </div>
          <textarea
            type="text"
            class="form-control"
            placeholder="Mensagem"
            @keypress="handleKeyPressed"
            v-model.trim="newMessage"
          />
          <div class="input-group-append">
            <!-- <button type="button" class="input-group-text">
              <i class="mdi mdi-paperclip icon-sm"></i>
            </button> -->
          </div>
          <div class="input-group-append">
            <button type="submit" class="input-group-text">
              <i class="mdi mdi-send icon-sm"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- <button @click="scrollToBottom">cc</button> -->

    <b-modal id="modal-file-1" title="Selecionar arquivo" hide-footer>
      <div class="d-flexs">
        <b-form-file v-model="file" ref="file-input" class="mb-2"></b-form-file>

        <b-button
          variant="danger"
          :disabled="loadingSendFile"
          class="mr-2"
          @click="file = null"
          >Limpar</b-button
        >
        <!-- <b-button variant="success" @click="sendMessageFile">Enviar</b-button> -->

        <b-button
          type="submit"
          variant="success"
          class="mr-2"
          :disabled="loadingSendFile"
          @click="sendMessageFile"
        >
          <template v-if="loadingSendFile">
            <b-spinner small type="grow"></b-spinner>
            Enviando...
          </template>
          <template v-else> Enviar </template>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MessageTypeText from "./MessageTypeText.vue";
import MessageTypeImage from "./MessageTypeImage.vue";
import MessageTypeAudio from "./MessageTypeAudio.vue";
import MessageTypeVideo from "./MessageTypeVideo.vue";
import MessageTypePDF from "./MessageTypePDF.vue";
import StatusMessageSend from "./StatusMessageSend.vue";

import { VEmojiPicker } from "v-emoji-picker";
import Header from './Header.vue';


export default {
  props: ["conversationProxy", "resetSelectedConversation", "usernameChat"],
  components: {
    MessageTypeText,
    MessageTypeImage,
    MessageTypeAudio,
    MessageTypeVideo,
    MessageTypePDF,
    StatusMessageSend,
    VEmojiPicker,
    Header
  },
  data() {
    return {
      messages: [],
      newMessage: "",
      boundConversations: new Set(),
      file: null,
      loadingSendFile: false,
      author: "",
      showEmoji: false,
      suave: false,
      // usernameChat: "",
    };
  },
  mounted() {
    // this.usernameChat = localStorage.getItem("usernameChat");

    if (this.conversationProxy) {
      this.loadMessagesFor(this.conversationProxy);

      if (!this.boundConversations.has(this.conversationProxy)) {
        // console.log("cheguei aqui mounted ***");
        let newConversation = this.conversationProxy;
        newConversation.on("messageAdded", (m) =>
          this.messageAdded(m, newConversation)
        );
        this.boundConversations = new Set([
          ...this.boundConversations,
          newConversation,
        ]);
      }
    }

    this.getAuthorMessage();
    // this.scrollToBottom();

    // this.scrollToBottom()
  },
  methods: {
    // ...mapMutations(["setBoundConversations"]),
    loadMessagesFor(thisConversation) {
      if (this.conversationProxy) {
        // console.log(this.conversationProxy.participants.size);
        if (this.conversationProxy === thisConversation) {
          thisConversation
            .getMessages(200)
            .then((messagePaginator) => {
              if (this.conversationProxy === thisConversation) {
                // console.log(messagePaginator.items);
                this.messages = messagePaginator.items;
                // console.log(this.messages);

                // console.log(this.messages.length);
              }
            })
            .catch((err) => {
              console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
              // this.setState({ loadingState: "failed" });
            });
        }
      }
    },
    sendMessage() {
      if (this.newMessage == "") return;
      const message = this.newMessage;
      this.conversationProxy.sendMessage(message);
      this.newMessage = "";
      this.showEmoji = false;
    },

    sendMessageFile() {
      if (!this.file) return;

      this.loadingSendFile = true;
      this.conversationProxy
        .sendMessage({
          contentType: this.file.type,
          media: this.file,
          filename: this.file.name,
        })
        .then(() => {
          this.$bvModal.hide("modal-file-1");
          this.file = null;
          this.loadingSendFile = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingSendFile = false;

          alert("Erro");
        });
    },

    messageAdded(message, targetConversation) {
      if (targetConversation === this.conversationProxy) {
        // console.log("*** messageAdded chamado");
        this.messages = [...this.messages, message]; //(prevState)=> this.messages = [...prevState.messages, message]
      }
    },
    scrollToBottom() {
      const chatArea = document.querySelector(".chat-container-wrapper");
      if (!this.suave) {
        if (chatArea) {
          chatArea.scrollTop = chatArea.scrollHeight;
          this.suave = true;
        }
      } else {
        // this.scrollToBottomSmooth()
        chatArea.classList.add("scroll__behavior__smooth");
        if (chatArea) {
          chatArea.scrollTop = chatArea.scrollHeight;
        }
      }
    },

    
    getAuthorMessage() {
      this.author = localStorage.getItem("usernameChat");
      // console.log(this.author);
    },
    selectEmoji(emoji) {
      this.newMessage = this.newMessage + emoji.data;
    },
    handleKeyPressed(e) {
      const keyCode = e.which || e.keyCode;
      // 13 represents the Enter key           


      if (keyCode === 13 && !e.shiftKey) {
        // Don't generate a new line
        e.preventDefault();     
        this.sendMessage()   
      }
    },

    
  },
  computed: {
    // ...mapState(["boundConversations"]),
  },
  watch: {
    conversationProxy() {
      this.loadMessagesFor(this.conversationProxy);

      //altera proprietadades da velocidade do scroll
      this.suave = false;
      const chatArea = document.querySelector(".chat-container-wrapper");
      if (chatArea) {
        chatArea.classList.remove("scroll__behavior__smooth");
      }
    },
    messages() {
      this.$nextTick(function () {
        this.scrollToBottom();
      });
      this.getAuthorMessage();
    },
  },
  updated() {
    // console.log("updated");

    // console.log(  this.boundConversations.has(this.conversationProxy)  );

    if (this.conversationProxy) {
      if (!this.boundConversations.has(this.conversationProxy)) {
        // console.log(this.boundConversations);
        // console.log("cheguei aqui updated ***");

        let newConversation = this.conversationProxy;
        newConversation.on("messageAdded", (m) => {
          // console.log("ouvinte adcionado");

          this.messageAdded(m, newConversation);
        });
        this.boundConversations = new Set([
          ...this.boundConversations,
          newConversation,
        ]);

        // this.setBoundConversations(
        //   new Set([
        //   ...this.boundConversations,
        //   newConversation,
        //   ])

        // )
      }
    }
    // this.scrollToBottom()
  },
  beforeDestroy() {
    // console.log("vai destruit view");
  },
};
</script>

<style  lang="scss">
.conversation__view {
  /* width: 75%; */
  flex: 1;
  background-image: url("https://web.telegram.org/z/chat-bg-pattern-dark.ad38368a9e8140d0ac7d..png");
  /* background-image: url("https://web.telegram.org/z/chat-bg-pattern-light.ee148af944f6580293ae..png"); */
  background-position: top right;
  background-size: 510px auto;
  background-repeat: repeat;
  background-color: #0f0f0f;

  header {
    background: #212121;
    align-items: center;
  }

  .conversation__view__hours {
    position: absolute;
    right: 30px;
    font-size: 10px;
    bottom: 0;
  }
  .chat-message {
    margin-right: 0 !important;
    margin-left: 0 !important ;
    max-width: 55% !important;
    min-width: 160px;
    /* background: #766ac8; */
    /* background: #766ac8 !important; */
  }
  .outgoing-chat .chat-message {
    background: #766ac8 !important;
  }

  .my__user__name {
    /* background: var(--primary); */
    background: #766ac8 !important;

    padding: 10px;
    border-radius: 5px;
  }

  .another__user__name {
    background: var(--dark);
    padding: 16px;

    border-radius: 5px;
  }

  /* input[type="file"] {
    display: none;
  } */
  /* label {
    padding: 20px 10px;
    width: 200px;
    background-color: #333;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 10px;
    cursor: pointer;
    } */

  .chat-container-wrapper {
    min-height: auto;
    /* scroll-behavior: smooth; */
  }
  .scroll__behavior__smooth {
    scroll-behavior: smooth;
  }

  .VEmojiPicker {
    .container-emoji {
      .emoji {
        border: 0 !important;
      }
    }
  }
}
</style>