import Vue from "vue";
import TwilioService from "../../../services/twilio.service";
import { Client } from "@twilio/conversations";


const chat = Vue.observable({
  // listAllMessages: [],
  isCurrentConversation: { sid: "" },
  updateMessages: undefined,
  userNameChat: undefined,
  client: undefined, //client not use ???
  // currentProjectInfo: {
  //   telefone: ""
  // }
});

// provisorio
export function resetIscurrentConversation(){
  chat.isCurrentConversation = { sid: "" }
}



export default chat;

// Overwrite

const initialState = Vue.observable({
  conversationsClient: undefined,
  statusString: "desconectado",
  conversations: [],
  listAllMessages: [],
  newMessage: undefined,
  newConversation: undefined,
  activeConversation: undefined,
  // activeConversation: {sid: undefined},
  activedSound: true,
  userNameChat: "",
  infoCurrentProjectInfo: undefined,

});

export const statusString = ()=> initialState.statusString;
export const conversations = ()=> initialState.conversations;
export const listAllMessages = ()=> initialState.listAllMessages;
export const conversationsClient = ()=> initialState.conversationsClient;
export const newMessage = ()=> initialState.newMessage;
export const newConversation = ()=> initialState.newConversation;
export const activeConversation = ()=> initialState.activeConversation;
export const activedSound = ()=> initialState.activedSound;
export const userNameChat = ()=> initialState.userNameChat;
export const infoCurrentProjectInfo = ()=> initialState.infoCurrentProjectInfo;

export function setInfoCurrentProjectInfo (value){  
  initialState.infoCurrentProjectInfo = value;
}




export function setUserNameChat (value){  
  initialState.userNameChat = value;
}



export  function setActiveSound(value) {
  initialState.activedSound = value;
}

export function setActiveConversation(value){
  initialState.activeConversation = value
}





export async function getTokenConversation() {

  const usernameChat = localStorage.getItem("usernameChat");
  if (!usernameChat) return;

  initialState.conversations = [];

  try {
    const res = await TwilioService.getTokenConversation(usernameChat);
    // console.log(res.data);
    const token = res.data;

    // console.log(token);
    startConversations(token);
  } catch (error) {
    console.error(error);
  }
}



// LOGIN
async function startConversations(token) {
//eslint-disable-next-line
  const client = new Client(token);
  initialState.conversationsClient = client;
  addDeviceListeners(client);

}

// LOGOUT
export async function logoutConversation() {
  try {
    await initialState.conversationsClient.shutdown();
    //  console.log(res);
    initialState.conversations = [];

    
    initialState.conversationsClient = undefined;
    localStorage.removeItem("usernameChat");
    initialState.conversationsClient?.removeAllListeners();


    // this.selectedConversationSid = null;
    // this.selectedConversation = undefined;
    // localStorage.removeItem("usernameChat");

  } catch (error) {
    console.error(error);
  }
}











function addDeviceListeners(client) {
  client.on("connectionStateChanged", (state) => {

    if (state === "connecting")
    initialState.statusString = "Conectando ao Twilio……";

    if (state === "connected") {
      initialState.statusString = "Conectado";
    }
    if (state === "disconnecting")
    initialState.statusString = "Desconectando do Twilio…";

    if (state === "disconnected") initialState.statusString = "disconnected";

    if (state === "denied") initialState.statusString = "Falhou ao conectar.";
    // ----------------
  });

  client.on("messageAdded", (state) => {
    // console.log("messageAdded ", state);
    // console.log("messageAdded");
    // notifyNewMessage(state);
    initialState.newMessage = state;
  });

  // client.on("messageUpdated", (state) => {
  //   console.log("*** messageUpdated ", state);

  // });

  client.on("conversationJoined", (conversation) => {
    // console.log("conversationJoined ", conversation);
    initialState.conversations = [...initialState.conversations, conversation];
    orderArrayByLastTime();
  });

                  client.on("conversationLeft", (thisConversation) => {
    //Disparado quando o cliente sai de uma conversa.
    // console.log("conversationLeft ", thisConversation);
                     initialState.conversations = [
      ...initialState.conversations.filter((it) => it !== thisConversation),
    ];
  });

  client.on("conversationAdded", async (state) => {  
    initialState.newConversation = state;
  });

  client.on("conversationRemoved", async () => {
    console.log("conversationRemoved");
  });

  client.on("conversationUpdated", async () => {
    // console.log("conversationUpdated ", conversation);
    // const client = client
    // const subscribedConversations = await client.getSubscribedConversations();

    // console.log("atualizada conversa");
    const subscribedConversations =
      await client.getSubscribedConversations();

    initialState.conversations = subscribedConversations.items;
    initialState.listAllMessages = subscribedConversations.items;

    orderArrayByLastTime();
  });

  client.on("tokenExpired", async () => {
    // console.log("tokenExpired");
    getTokenConversation();
  });
}



function orderArrayByLastTime() {
  // console.log("orderArray chamado");
  initialState.conversations = initialState.conversations.sort((a, b) => {
    return (
      (b.lastMessage?.dateCreated || b.dateUpdated) -
      (a.lastMessage?.dateCreated || a.dateUpdated)
    );
  });

  initialState.listAllMessages = initialState.conversations;  
}

