<template>
  <section class="main-view">
    <!-- <Header /> -->
    <div class="container-scroller">
      <Header />
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view></router-view>
          </div>
          <!-- content wrapper ends -->
          <Footer />
          <Call v-if="$store.getters['auth/hasPermission']('Importar Base')"  />

          <PhoneFloat
            v-if="$store.getters['auth/hasPermission']('Importar Base')"
          />

          <!-- <IncomingCall
            v-if="$store.getters['auth/hasPermission']('Importar Base')"
          /> -->
          <!-- <GlobalNotificationsChat/> -->

          <!-- <Notification/> -->
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>

    <!-- <ChooseProject/> -->
    <ConversationConfig />
  </section>
</template>

<script>
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Call from "@/components/call/Call.vue";
import PhoneFloat from "@/components/call/PhoneFloat";

import ConversationConfig from '../components/conversation/ConversationConfig.vue';

export default {
  name: "layout",
  components: {
    Header,
    Footer,
    PhoneFloat,
    Call,
    ConversationConfig
  },

  data() {
    return {};
  },
  methods: {
  },

  computed: {
  },

  watch: {},
  mounted() {

  },

  beforeMount() {},
};
</script>

<style scoped lang="scss">
.content-wrapper {
  max-width: 1366px;
  max-width: 1980px;
  padding-top: 0;
}
.main-panel {
  margin-top: 109px;
}
</style>

<style>

/* mudar para css global */
.page-item.active .page-link {
  z-index: 0;
}

.submenu {
  top: 40px !important;
}

.border--solid {
  border: 1px solid var(--primary) !important;
}

.table-dark > td {
}
.table-active > td,
.table-dark > td {
  background-color: var(--dark) !important;
}

.font-size-14{
  font-size: 14px ;
}









</style>
