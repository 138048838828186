<template>
  <div class="container">
    <button
      v-if="inCall"
      class="btn p-0"
      v-b-tooltip.hover.right="'Chamada em andamento...'"
      v-on:click="setCall"
    >
      <i class="Phone is-animating"></i>
    </button>

    <button
      v-else
      v-on:click="setCall"
      class="btn btn-rounded btn-icon btn-primary"
      v-b-tooltip.hover.right="
        'Atalho: precione Ctrl + Alt para abrir o telefone'
      "
    >
      <i class="icon-phone"></i>

      <span class="bg-danger mr-2 is__online" v-if="!isOnlineIncomingCall" ></span>
      <span class="bg-success text-success mr-2 is__online" v-else></span>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { isOnlineIncomingCall } from "@/store/module-observable/call";

// isOnlineIncomingCall

export default {
  methods: {
    ...mapMutations(["setCall"]),
  },
  computed: {
    ...mapState(["inCall"]),
    isOnlineIncomingCall,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  bottom: 5px;
  left: 15px;
  z-index: 9;
  width: auto;
}
</style>



<style lang="scss" scoped>
.Phone {
  position: relative;
  display: block;
  margin: 0;
  width: 1em;
  height: 1em;
  font-size: 7vmin;
  /* background-color: #3498db; */
  background-color: var(--danger);
  border-radius: 0.5em;
  box-shadow: 0 0 0 0em rgba(#3498db, 0),
    /* 0em 0.05em 0.1em rgba(#000000, 0.2); */ 0em 0.05em 0.1em
      rgba(#ebd5d5, 0.2);

  transform: translate3d(0, 0, 0) scale(1);
}

.Phone::before,
.Phone::after {
  position: absolute;
  content: "";
}

.Phone::before {
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  background-color: rgba(#fff, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0);
}

.Phone::after {
  top: 0.25em;
  left: 0.25em;
  width: 0.5em;
  height: 0.5em;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}

.Phone.is-animating {
  animation: phone-outer 1300ms infinite;

  &::before {
    animation: phone-inner 1300ms infinite;
  }

  &::after {
    animation: phone-icon 1300ms infinite;
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#c7cfd5, 0), 0em 0.05em 0.1em rgba(#d3c1c1, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(#c2ced6, 0.1),
      0em 0.05em 0.1em rgba(#ddc9c9, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(#c6d5df, 0),
      0em 0.05em 0.1em rgba(#d5c5c5, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#d5e3ec, 0), 0em 0.05em 0.1em rgba(#d5e3ec, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0);
  }
  2% {
    transform: translate3d(0.01em, 0, 0);
  }
  4% {
    transform: translate3d(-0.01em, 0, 0);
  }
  6% {
    transform: translate3d(0.01em, 0, 0);
  }
  8% {
    transform: translate3d(-0.01em, 0, 0);
  }
  10% {
    transform: translate3d(0.01em, 0, 0);
  }
  12% {
    transform: translate3d(-0.01em, 0, 0);
  }
  14% {
    transform: translate3d(0.01em, 0, 0);
  }
  16% {
    transform: translate3d(-0.01em, 0, 0);
  }
  18% {
    transform: translate3d(0.01em, 0, 0);
  }
  20% {
    transform: translate3d(-0.01em, 0, 0);
  }
  22% {
    transform: translate3d(0.01em, 0, 0);
  }
  24% {
    transform: translate3d(-0.01em, 0, 0);
  }
  26% {
    transform: translate3d(0.01em, 0, 0);
  }
  28% {
    transform: translate3d(-0.01em, 0, 0);
  }
  30% {
    transform: translate3d(0.01em, 0, 0);
  }
  32% {
    transform: translate3d(-0.01em, 0, 0);
  }
  34% {
    transform: translate3d(0.01em, 0, 0);
  }
  36% {
    transform: translate3d(-0.01em, 0, 0);
  }
  38% {
    transform: translate3d(0.01em, 0, 0);
  }
  40% {
    transform: translate3d(-0.01em, 0, 0);
  }
  42% {
    transform: translate3d(0.01em, 0, 0);
  }
  44% {
    transform: translate3d(-0.01em, 0, 0);
  }
  46% {
    transform: translate3d(0em, 0, 0);
  }
}
</style>



<style lang="scss" scoped>
.is__online {
  width: 13px;
  height: 13px;
  /* background: red; */
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}
</style>