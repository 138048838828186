<template>
  <div
    class="d-flex"
    :class="message.author === usernameChat ? 'justify-content-end ' : ' '"
  >

  

    <div
      class="chat-message position-relative"
      :class="message.author === usernameChat ? '' : 'ml-0'"
    >
      <p>
        {{ message.body }}
      </p>

      <span class="conversation__view__hours">{{
        message.state.timestamp.toLocaleString()
      }}</span>

    </div>
    


    <!-- <h1>{{usernameChat}}</h1> -->

  </div>
</template>

<script>
export default {
  props: ["message"],
 data(){
   return{
    usernameChat: undefined
   }
 },
 mounted(){
   this.getUserName()
 },
  methods: {
    getUserName() {
      this.usernameChat = localStorage.getItem("usernameChat") || "PIATTINO";
    },
  },
};
</script>

<style>
</style>