<template>
  <div
    class="d-flex"
    :class="message.author === usernameChat ? 'justify-content-end ' : ' '"
  >
    <div
      class="position-relative image"
      :class="message.author === usernameChat ? '' : 'ml-0'"
    >
      <!-- <p>VIDEO</p> -->

      <div :class="message.author === usernameChat ? 'my__user__name ' : 'another__user__name'">
        <!-- <img :src="url" alt="image" class="w-100 rounded" v-if="url" >     -->

        <video width="320" autoplay muted height="240" controls v-if="url" loop>
          <source :src="url" type="video/mp4" />
          <source :src="url" type="video/ogg" />
          Your browser does not support the video tag.
        </video>

        <b-skeleton-img
          v-else
          class="position-relative image"
          width="100%"
          height="200px"
        ></b-skeleton-img>
      </div>

      <span class="conversation__view__hours">{{
        message.state.timestamp.toLocaleString()
      }}</span>
    </div>

    <!-- <h1>{{usernameChat}}</h1> -->
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      usernameChat: undefined,
      url: "",
    };
  },
  mounted() {
    this.getUserName();
    this.getFileUrl();
  },
  methods: {
    getUserName() {
      this.usernameChat = localStorage.getItem("usernameChat") || "PIATTINO";
    },
    async getFileUrl() {
      // console.log("media ", this.message.media);
      const media = this.message.media;
      const res = await media.getContentTemporaryUrl();

      // console.log(res);
      this.url = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 20%;
  width: auto;
  max-width: 500px;
}
</style>