<template>
  <section
    class="incoming__call"
    :class="!showIncomingCall ? 'incoming_call__container--hide' : ''"
  >
    <!-- <div class="incoming_call__button">
      
      <span class="bg-danger mr-2 is__online" v-if="!device"></span>
      <span class="bg-success text-success mr-2 is__online" v-else></span>

     

      <b-button
       @click="() => (this.hideContainer = !this.hideContainer)"
        class="btn btn-rounded btn-icon btn-primary incoming_call__button__open"
        variant="primary"
        v-b-tooltip.top
        v-b-tooltip.hover="{
          variant: !device ? 'danger' : 'success',
        }"
        :title="!device ? 'INDISPONÍVEL PARA RECEBER CHAMADAS' : 'DISPONÍVEL PARA RECEBER CHAMADAS'"
      >
        <i class="mdi mdi-phone-incoming"></i>
      </b-button>


      
    </div> -->

    <!-- <div
      class="card bg-dark incoming_call__container"
      :class="showIncomingCall ? 'incoming_call__container--hide' : '' "
    > -->

    <div class="card bg-dark">
      <div class="top">
        <div>
          <!-- <span class="text-secondary">Device: </span>  -->
          <span class="text-muted">{{ messageReady }}</span>
        </div>

        <button
          class="btn btn-outiline-primary"
          v-on:click="setShowIncomingCall(false)"
          v-b-tooltip.hover.top="'Minimizar'"
        >
          -
        </button>
      </div>

      <div class="card-body mt-3">
        <div class="d-flex justify-content-end align-items-center">
          <!-- <div>
            <h5 class="text-muted">Twilio Client:</h5>
            <strong class="text-muted">{{ twillioIdentity }}</strong>
          </div> -->

          <b-button v-if="!device" @click="getToken" variant="primary" size="sm"
          v-b-tooltip.hover.right="
              'Clique para ativar o recebimento de chamadas'
            "
          
          >
            <!-- Ativar recebimento de chamadas -->
            Ativar
          </b-button>
          <b-button
                v-b-tooltip.hover.right="
              'Clique para desativar o recebimento de chamadas'
            "
            variant="danger"
            v-else
            @click="disableDevice"
            class=" btn-sm"
            :disabled="isActiveCall || incoming"

          >
            <!-- Desativar recebimento de chamadas -->
            Desativar
          </b-button>
        </div>

        <div class="mt-4">
          <div class="w-75">
            <div class="d-flex">
              <div>
                <h4>{{ messageStatusCall }}</h4>
                <h5>{{ telefone }}</h5>
              </div>
              <div>
                <h5 class="ml-5" v-if="timeCount">
                  {{ timeCount | formatForMinutes }}
                </h5>
              </div>
            </div>

            <!-- <button @click="getToken">Start device</button> -->
            <div v-if="incoming">
              <button
                @click="acceptIncomingCall"
                class="btn btn-outline-success"
              >
                Atender
              </button>
              <button
                @click="rejectIncomingCall"
                class="btn btn-outline-danger ml-2"
              >
                Recusar
              </button>
            </div>

            <div v-if="isActiveCall">
              <button
                @click="hangupIncomingCall"
                class="btn btn-outline-danger"
              >
                Desligar
              </button>
            </div>

            <div v-show="show.btnMic" class="mic mt-4">
              <button
                class="btn btn-outline-secondary"
                v-show="!show.mic"
                id="mute"
                @click="muteCall"
              >
                <i
                  class="mdi mdi-microphone-outline"
                  style="font-size: 20px"
                ></i>
              </button>

              <button
                id="som"
                class="btn btn-outline-secondary"
                v-show="show.mic"
                @click="somCall"
              >
                <i class="mdi mdi-microphone-off" style="font-size: 20px"></i>
              </button>

              <p class="text-warning m-0" v-show="show.mic">
                Microfone Desativado
              </p>
            </div>
          </div>

          <div class="incoming__call__logs">
            <div id="log-incoming-call"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <button @click="Start">Start</button>
    <button @click="DesStart">DesStart</button> -->

    <!-- <button @click="time">Start</button>
  <button @click="clearTime">limpar</button> -->
  </section>
</template>

<script>
import { Device } from "@twilio/voice-sdk";
// import axios from "axios";
import TwilioService from "../../services/twilio.service";
import { mapState, mapMutations } from "vuex";

import {
  showIncomingCall,
  setShowIncomingCall,
  setIsOnlineIncomingCall
} from "../../store/module-observable/call";

export default {
  data() {
    return {
      device: undefined,
      currentCall: undefined,
      // hideContainer: false,
      messageReady: "DEVICE DESATIVADO",
      telefone: "",
      incoming: false,
      isActiveCall: false,
      messageStatusCall: "",
      twillioIdentity: "",
      timeCount: "",
      show: {
        mic: false,
        btnMic: false,
        container: false,
      },
    };
  },
  methods: {
    ...mapMutations(["setCall"]),
    setShowIncomingCall,
    setIsOnlineIncomingCall,
    async getToken() {
      try {
        // const res = await TwilioService.getToken(
        //   this.$store.state.auth.user.email
        // );

        // const res = await axios.post(
        //   "https://002f-2804-14c-64-8763-e5b1-7a7b-6b01-60f6.ngrok.io/token",
        // { nome: this.$store.state.auth.user.email }
        // );

        // const res = await TwilioService.getToken(this.$store.state.auth.user.email)
        const res = await TwilioService.getToken(
          this.$store.state.auth.user.email
        );

        // console.log("identificador " + res.data.identity);
        this.twillioIdentity = res.data.identity;

        this.log(this.twillioIdentity);

        // console.log("Token obtido.");
        this.log("Token obtido.");

        const token = res.data.token;

        this.intitializeDevice(token);

        // console.log(this.device);
      } catch (error) {
        console.log(error);
        console.log("Could not get a token from server!");
      }
    },

    intitializeDevice(token) {
      this.log("Initializing");
      this.device = new Device(token, {
        edge: "ashburn",
        tokenRefreshMs: 30000,
        // allowIncomingWhileBusy: true
      });

      this.addDeviceListeners();
      this.device.register();

      this.setIsOnlineIncomingCall(true) // global
      
    },

    addDeviceListeners() {
      this.device.on("tokenWillExpire", () => {
        console.log("token vai experirar");
        this.log("atualizando token ");
        // console.log("atualizando token ");
        this.getToken();
      });

      this.device.on("registered", () => {
        // console.log("Twilio.Device pronto para fazer e receber chamadas !");
        this.log("Twilio.Device pronto para receber chamadas !");
        this.messageReady = "Pronto para receber chamadas";

        // callControlsDiv.classList.remove("hide");
      });

      this.device.on("unregistered", () => {
        // console.log("Twilio.Device pronto para fazer e receber chamadas !");
        this.log("Twilio.Device unregistered");
        // this.messageReady = "Twilio.Device NÃO registrado".toUpperCase();
      });

      this.device.on("error", (error) => {
        console.log("Twilio.Device Error: " + error.message);
        this.log("Twilio.Device Error: " + error.message);
      });

      this.device.on("incoming", this.incomingEvent);
    },

    acceptIncomingCall() {
      if (this.currentCall) {
        this.currentCall.accept();
        // console.log("Chamada aceita.");
        this.log("Chamada aceita.");
        this.messageStatusCall = "chamada ativa";

        this.incoming = false;
        this.isActiveCall = true;
        this.time();
      }
    },
    rejectIncomingCall() {
      if (this.currentCall) {
        this.currentCall.reject();
        // console.log("Chamada Rejeitada");
        // this.log("Chamada Rejeitada");
      }
    },
    hangupIncomingCall() {
      if (this.currentCall) {
        this.currentCall.disconnect();
        this.log("Chamada Desligada");

        // console.log("Chamada Desligada");
        this.incoming = false;
      }
    },
    disableDevice() {
      if (this.device) {
        this.device.destroy();
        this.device = undefined;
        this.log("Device Desativado");
        this.messageReady = "Indisponível";
        this.twillioIdentity = "";
        this.setIsOnlineIncomingCall(false)

        
      }
    },

    log(message) {
      const logDiv = document.getElementById("log-incoming-call")
        ? document.getElementById("log-incoming-call")
        : document.querySelector("body");
      logDiv.innerHTML += "<p>&gt;&nbsp;" + message + "</p>";
      logDiv.scrollTop = logDiv.scrollHeight;
    },
    incomingEvent(call) {
      // console.log("this.incoming ", this.incoming);
      //  return
      // this.hideContainer = false;
      this.setCall(true)
      this.setShowIncomingCall(true)

      this.log("recebendo chamada .... " + call.parameters.From);
      this.messageStatusCall = "recebendo chamada ...";
      this.incoming = true;
      this.telefone = call.parameters.From;

      this.show.btnMic = true;

      // console.log("esta ocupado ? : ", this.device.isBusy);
      // console.log("device :", this.device);

      this.currentCall = call;

      call.on("cancel", () => {
        // console.log("Chamada finalizada");
        this.log("Chamada finalizada");
        this.telefone = "";
        this.incoming = false;
        this.isActiveCall = false;
        this.messageStatusCall = "";
        this.clearTime();
        this.show.btnMic = false;
        this.show.mic = false;
      });

      call.on("disconnect", () => {
        // console.log("Chamada finalizada");
        this.log("Chamada Desconectada");
        this.telefone = "";
        this.incoming = false;
        this.isActiveCall = false;
        this.messageStatusCall = "";
        this.clearTime();
        this.show.btnMic = false;
        this.show.mic = false;
      });
      call.on("reject", () => {
        // console.log("Chamada Rejeitada");
        this.log("Chamada Rejeitada");
        this.telefone = "";
        this.incoming = false;
        this.isActiveCall = false;
        this.messageStatusCall = "";
        this.show.btnMic = false;
        this.show.mic = false;
      });
    },
    time() {
      this.stopInterval = setInterval(() => {
        this.timeCount++;
      }, 1000);
    },
    clearTime() {
      clearInterval(this.stopInterval); //limpa cronômetro
      this.timeCount = ""; //limpa cronômetro
    },
    muteCall() {
      if (this.currentCall) {
        this.currentCall.mute(true);
        this.show.mic = true;
        console.log("mudo");
      }
    },

    somCall() {
      if (this.currentCall) {
        this.currentCall.mute(false);
        this.show.mic = false;
        console.log("som");
      }
    },
  },
  mounted() {
    this.getToken(); //inicializa o token
  },
  beforeDestroy() {

   
    if (this.currentCall) {
      this.currentCall.reject();
      this.currentCall.disconnect();
      // this.device.disconnect()

      this.incoming = false;
    }
    if (this.device) {

      this.device?.removeAllListeners()
      this.device?.destroy();

    }
  },

  watch: {
   

    incoming() {
      //remove e coloca o enento de escultar chamada recebida
      if (this.incoming == true) {
        // this.device.off("incoming", this.incomingEvent);
        this.device.unregister();
      } else {
        // this.device.on("incoming", this.incomingEvent);
        this.device.register();
      }
    },

    inCall() {
      // console.log(this.inCall);

      if (this.inCall) {
        if (this.device) {
          this.device.unregister();
        }
      } else {
        if (this.device) {
          this.device.register();
        }
      }
    },
  },
  computed: {
    ...mapState(["inCall"]),
    showIncomingCall,
  },
};
</script>

<style lang="scss" scoped>
.incoming__call {
  z-index: 9;
  z-index: -1;
  width: 35%;
  
  width: 300px;

  /* position: fixed; */
  /* right: 0; */

  position: absolute;
  left: 100%;
  bottom: 0;
  transition: 0.2s;

  .incoming_call__button {
    position: relative;
    position: fixed;
    bottom: 5px;
    right: 15px;
  }

  .incoming_call__button__open {
    /* position: fixed;
    bottom: 5px;
    right: 15px; */
    /* z-index: 1; */
    /* top: -50px; */
  }

  .incoming_call__container {
    width: 100%;

    transition: 0.2s !important;

    margin-left: -150%;
  }

  .incoming__call__logs {
    width: 100%;
  }

  #log-incoming-call {
    border: 1px solid #686865;
    width: 35%;
    height: 9.5em;
    margin-top: 2.75em;
    text-align: left;
    padding: 1.5em;
    /* float: right; */
    overflow-y: scroll;

    /* position: absolute; */
    background: black;
    width: 100%;
  }

  div#log-incoming-call p {
    color: #686865;
    font-family: "Share Tech Mono", "Courier New", Courier, fixed-width;
    font-size: 1.25em;
    line-height: 1.25em;
    margin-left: 1em;
    text-indent: -1.25em;
    width: 90%;
  }
  .is__online {
    width: 13px;
    height: 13px;
    /* background: red; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
  }

  .mic {
    position: relative;

    /* > p {
      width: 100%;
      width: 200px;
      position: absolute;
      right: 0;
    } */
  }
}

.incoming_call__container--hide {

  transition: 0.2s;

  left: 10%;
}

.top {
  position: absolute;
  /* top: -24px; */
  width: 100%;
  border: 1px solid #ffffff21;
  background: #191c24;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 0 5px;

  button {
    &:hover {
      background: rgba(255, 255, 255, 0.217);
    }
  }
}
</style>
