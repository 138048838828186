<template>
  <!-- <input v-on:keyup.ctrl.32="makeOutgoingCall"> -->

  <div class="container" :class="call.showComponent ? '' : 'hide'" id="call">
    <div class="top">
      <div>
        <span class="text-muted">Telefone</span>
        <b-button variant="btn-outline">
          <i
            class="mdi mdi-settings"
            title="logs"
            @click="() => (this.showConfigMic = !this.showConfigMic)"
          ></i>
        </b-button>

        <b-button
          variant="btn-outline"
         
          :to="{ name: 'MinhasChamadas', params: { id: currentUser.id } }"
        >
          <i class="icon-call-out"
           v-b-tooltip.hover
          title="Histórico/Gravações"
          
          ></i>
        </b-button>

        <!-- <i
            class="mdi mdi-help-circle-outline btn"
           v-b-tooltip.hover.right="'Atalho: precione Ctrl + Espaço para abrir o telefone'"
            
          ></i> -->
      </div>

      <span class="text-muted">{{ msgStatusCall }}</span>

      <button
        class="btn btn-outiline-primary"
        v-on:click="setCall(false)"
        v-b-tooltip.hover.top="'Minimizar'"
      >
        -
      </button>
    </div>

    <div class="card card-statistics bg-dark">
      <!-- <span v-if="numberFrom" class="ml-3 mt-1">{{numberFrom.description}}</span> -->
      <div class="card-body">
        <div class="row flex-nowrap justify-content-between align-items-center">
          <div>
            <label for="" style="font-size: 0.875rem">Seu número Twilio:</label>
            <input
              v-if="options.length == 0"
              type="text"
              class="form-control my-2"
              placeholder="+5511999999999"
              v-model="numberFrom"
              title="formato internacional ex: +5511999999999"
            />
            <b-form-select
              v-else
              v-model="numberFrom"
              :options="options"
              size="sm"
              class="mb-3"
            ></b-form-select>
          </div>

          <b-button
            variant="primary"
            @click="setShowIncomingCall()"
            v-b-tooltip.hover.top="'Chamadas recebidas'"
          >
            <i class="mdi mdi-phone-incoming"></i>
          </b-button>
        </div>

        <!-- <button @click="colar">colar</button> -->
        <div class="row flex-nowrap" >

          <div class="w-100"

          @keyup.enter="makeOutgoingCall"

          
          >

          <TheMask
           @keyup.enter="makeOutgoingCall"
            v-model="number"
            type="text"
            :mask="[
              '+## (##) ####-####',
              '+## (##) #####-####',
              '+### (###) #####-####',
            ]"
            class="form-control"
            placeholder="Número do telefone"
            id="phone-number"
            :disabled="this.$store.state.inCall"

          />
          </div>
          <!-- <div v-on:click.ctrl="makeOutgoingCall">Faça alguma coisa</div> -->
          <!-- <button v-on:click.ctrl.exact="makeOutgoingCall">A</button> -->

          <!-- <button v-on:click.ctrl="makeOutgoingCall">A</button>
         <button v-on:click.alt.67="makeOutgoingCall">A</button> -->

          <!-- <input v-on:keyup.ctrl.32="makeOutgoingCall"> -->

          <button
            class="btn btn-success ml-2"
            title="Ligar (enter)"
            @click="makeOutgoingCall"
            id="button-call"
          >
            <i class="icon-call-out"></i>
          </button>
          <button
            class="btn btn-danger ml-2"
            title="Desligar"
            id="button-hangup"
            @click="hangup"
            :disabled="!activeCall"
          >
            <i class="mdi mdi mdi-close"></i>
          </button>
        </div>

        <button
          class="btn position-absolute"
          v-b-tooltip.hover.right="'colar (apenas números)'"
          style="left: 3px"
          @click="handlePaste"
        >
          <i class="fa fa-paste"></i>
        </button>

        <div class="d-flex justify-content-between mt-3">
          <span class="text-muted mt-2 d-inline-block">
            <!-- Chamando <b-spinner small type="grow" class="ml-1"></b-spinner> -->
            <span>{{ msg }}</span>
          </span>

          <div v-show="show.btnMic" class="mic text-right">
            <button
              class="btn btn-outline-secondary"
              v-show="!show.mic"
              id="mute"
              @click="muteCall"
            >
              <i class="mdi mdi-microphone-outline" style="font-size: 20px"></i>
            </button>

            <button
              id="som"
              class="btn btn-outline-secondary"
              v-show="show.mic"
              @click="somCall"
            >
              <i class="mdi mdi-microphone-off" style="font-size: 20px"></i>
            </button>

            <p class="text-warning m-0" v-show="show.mic">
              Microfone Desativado
            </p>
          </div>
        </div>

        <span v-if="timeCount" class="text-muted">{{
          timeCount | formatForMinutes
        }}</span>
      </div>

      <div class="text-center text-muted">{{ digits }}</div>

      <div class="d-flex dialpad">
        <div class="dialpad__area">
          <template v-for="n in 13">
            <div :key="n">
              <button
                v-if="n < 10"
                @click="setDigits(n)"
                class="btn btn-inverse-primary"
                :disabled="!showDial"
              >
                {{ n }}
              </button>

              <button
                v-if="n == 10"
                @click="setDigits('*')"
                class="btn btn-inverse-primary"
                :disabled="!showDial"
              >
                *
              </button>
              <button
                v-if="n == 11"
                class="btn btn-inverse-primary"
                @click="setDigits(0)"
                :disabled="!showDial"
              >
                0
              </button>
              <button
                v-if="n == 12"
                class="btn btn-inverse-primary"
                @click="setDigits('#')"
                :disabled="!showDial"
              >
                #
              </button>
            </div>
          </template>

          <div class="w-100">
            <button
              @click="clearNumber"
              class="btn btn-inverse-primary w-100"
              :disabled="!showDial"
            >
              <i class="fa fa-long-arrow-left"></i>
            </button>
          </div>
        </div>

        <HistoryCall :setNumber="setNumber" :activeCall="activeCall" />
      </div>
    </div>

    <!-- // sound controls -->
    <div id="controls" v-show="showConfigMic">
      <button
        class="btn"
        @click="() => (this.showConfigMic = !this.showConfigMic)"
        style="width: 35px; position: absolute; top: 0; right: 10px"
      >
        X
      </button>

      <div id="info">
        <p class="instructions">Twilio Client:</p>
        <div id="client-name"></div>

        <div class="row flex-nowrap">
          <div>
            <label for="" style="font-size: 0.875rem">Seu número Twilio:</label>
            <input
              v-if="options.length == 0"
              type="text"
              class="form-control my-2"
              placeholder="+5511999999999"
              v-model="numberFrom"
              title="formato internacional ex: +5511999999999"
            />
            <b-form-select
              v-else
              v-model="numberFrom"
              :options="options"
              size="sm"
              class="mb-3"
            ></b-form-select>
          </div>
        </div>

        <!-- <div id="output-selection">
          <label>Entrada de Audio </label>
          <select id="ringtone-devices" multiple></select>
          <label>Saída de Audio </label>
          <select id="speaker-devices" multiple></select
          ><br />
          <a id="get-devices">Seeing unknown devices?</a>
        </div> -->
      </div>
      <div id="call-controls">
        <div id="log" class="card"></div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="twilio__alert"
      >
        {{ erroMessageTwilio }}
      </b-alert>
    </div>

    <div class="d-flex justify-content-center">
      <b-alert
        v-model="showReconectMenssage"
        variant="warning"
        dismissible
        class="twilio__alert"
      >
        Conexão Perdida, tentando reconectar...
      </b-alert>
    </div>

    <div class="d-flex justify-content-center">
      <b-alert
        v-model="showReconnectedMenssage"
        variant="success"
        dismissible
        class="twilio__alert"
      >
        Chamada Restabelecida
      </b-alert>
    </div>

    <SaveCallHistory
      v-if="showComponentSaveCallHistory"
      :payloadFromCall="payloadFromCall"
      :setShowComponentSaveCallHistory="setShowComponentSaveCallHistory"
    />

    <!-- <div class="incoming__component">
      
    </div> -->
    <IncomingCallVue />
  </div>
</template>

<script>
/* eslint-disable */

import TwilioService from "@/services/twilio.service.js";

import { TheMask } from "vue-the-mask";
import { mapState, mapMutations } from "vuex";
// import { Device } from "twilio-client";

import { Device } from "@twilio/voice-sdk";

import HistoryCall from "@/components/call/components/HistoryCall.vue";
import SaveCallHistory from "@/components/call/components/SaveCallHistory.vue";
import IncomingCallVue from "../incoming-call/IncomingCall.vue";

import { setShowIncomingCall } from "../../store/module-observable/call";

// import TwilioService from "../../services/twilio.service";

const RecentContactsKey = "RECENT_CONTACTS";

// document.getElementById("call-controls").style.display = "block";

export default {
  // directives: {mask},
  components: { TheMask, HistoryCall, SaveCallHistory, IncomingCallVue },
  data() {
    return {
      device: undefined,
      number: "",
      show: {
        // mic: false,
        mic: false,
        btnMic: false,
        container: false,
      },
      msgStatusCall: "",
      msg: "",
      // sound: undefined,
      activeCall: undefined,

      // speakerDevices: "",
      ringtoneDevices: "",
      timeCount: "",
      stopInterval: undefined,
      showConfigMic: false,

      showDismissibleAlert: false,
      erroMessageTwilio: "",

      showReconectMenssage: false,
      showReconnectedMenssage: false,

      // n: 0,
      digits: "",
      showDial: true,

      showComponentSaveCallHistory: false,
      payloadFromCall: {
        lead_id: "",
        notas: "",
        contato_telefone_id: "",
        twilio_sid: "",
        twilio_duration: "",
        twilio_status: "",
        twilio_from: "",
        twilio_to: "",
        twilio_start_time: "",
        twilio_end_time: "",
      },
      numberFrom: null,
      options: [
        // { value: null, text: 'Selecione um número:' },
        // { value: '551132303906', text: 'PIATTINO +551132303906' },
    
      ],
    };
  },

  methods: {
    //Faz a chamada
    ...mapMutations(["setCall", "setPhone", "setInCall"]),
    setShowIncomingCall,

    async getToken() {
      try {
        const res = await TwilioService.getToken(
          this.$store.state.auth.user.email
        );
        this.log("Token obtido.");
        // console.log("Token obtido.");
        const token = res.data.token;
        this.device = new Device(token, {
          edge: "ashburn",
          // 'tokenWillExpire' event will be emitted 30 seconds before the AccessToken expires
          tokenRefreshMs: 30000,
        });

        this.setClientNameUI(res.data.identity);

        // const  mydevice =this.device
        // const mydevice = new Device(token, { edge: 'ashburn' } )

        this.device.on("tokenWillExpire", () => {
          console.log("atualizando token ");
          this.getToken();
        });

        this.device.on("incoming", () => {
          console.log("recebendo chamada ....");
        });

        // console.log(this.device);
      } catch (error) {
        console.log(error);
        this.log("Could not get a token from server!");
      }
    },

    async makeOutgoingCall() {
      if (this.number <= 3) return;

      //  console.log(this.number);

      const params = {
        To: this.number,
        FROM: this.numberFrom.telefone,
      };

      // console.log(this.projetoSelecionado);
      const call = await this.device.connect({ params });
      this.activeCall = call;
      this.setInCall(true);
      const twilio_start_time = new Date();

      const projeto = this.projetoSelecionado
        ? this.projetoSelecionado.nome
        : "";
      // console.log(twilio_start_time);

      // console.log(projeto);

      // console.log(call);

      this.setCallToHistoy(params.To);

      this.msg = "Chamando ...";
      this.log("Chamando...");
      this.show.btnMic = true;

      const statusCall = setInterval(() => {
        this.msgStatusCall = call.status();
      }, 1000);

      call.on("accept", (call) => {
        this.log("Chamada estabelecida com sucesso");
        this.msg = "Em chamada...";
        this.showDial = true;
        this.time();

        // console.log(call);
      });

      call.on("disconnect", (call) => {
        this.log("Chamada encerrada. ");
        console.log("A chamada foi desconectada");
        const twilio_end_time = new Date();

        // console.log(call);

        // console.log([...call.customParameters.entries()][0][1]);

        if (this.phone.lead_id) {
          (this.payloadFromCall = {
            lead_id: this.phone.lead_id,
            contato_telefone_id: this.phone.contato_telefone_id,

            twilio_sid: call.parameters.CallSid,
            twilio_duration: this.timeCount,
            twilio_from: "",
            twilio_to: [...call.customParameters.entries()][0][1],
            twilio_start_time: twilio_start_time,
            twilio_end_time: twilio_end_time,
            projeto: projeto,
            contato_destino: this.phone.contato_destino,
          }),
            (this.showComponentSaveCallHistory = true);
        }

        this.clearTime(); //limpa cronômetro

        this.msg = "Finalizando...";
        this.setInCall(false);

        setTimeout(() => {
          this.msg = "";
          this.activeCall.mute(false);
          this.show.mic = false;

          this.show.btnMic = false;

          this.digits = "";
          // this.showDial = false;
          this.activeCall = undefined;

          setTimeout(() => {
            clearInterval(statusCall);
          }, 700);
        }, 500);
      });

      call.on("error", (error) => {
        this.showDismissibleAlert = true;
        this.erroMessageTwilio = "Twilio | code: " + ` ${error.message}`;

        console.log(error);
        this.log("Twilio.Device Error: " + error.message);
      });

      call.on("reject", (erro) => {
        console.log("The call was rejected.");
        console.log(erro);
      });

      call.on("reconnecting", (twilioError) => {
        console.log("Connectivity error: ", twilioError.message);
        this.log(twilioError.message);
        this.showReconectMenssage = true;
      });

      call.on("reconnected", () => {
        this.log("A chamada foi reconectada");
        this.showReconectMenssage = false;

        this.showReconnectedMenssage = true;

        setTimeout(() => {
          this.showReconnectedMenssage = false;
        }, 3000);
      });
    },

    setClientNameUI(clientName) {
      let div = document.getElementById("client-name");
      div.innerHTML = "Your client name: <strong>" + clientName + "</strong>";
    },

    log(message) {
      let logDiv = document.getElementById("log");
      logDiv.innerHTML += "<p>&gt;&nbsp;" + message + "</p>";
      logDiv.scrollTop = logDiv.scrollHeight;
    },

    time() {
      this.stopInterval = setInterval(() => {
        this.timeCount++;
      }, 1000);
    },

    clearTime() {
      clearInterval(this.stopInterval); //limpa cronômetro
      this.timeCount = ""; //limpa cronômetro
    },

    makeToast(variant = null) {
      this.$bvToast.toast(`${this.phone.numero} `, {
        title: "Dialpad",
        autoHideDelay: 1,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-left",
      });
    },

    setDigits(value) {
      value = value.toString();
      if (this.activeCall) {
        this.digits = `${this.digits + value}`;
        this.activeCall.sendDigits(value);
      } else {
        this.number = `${this.number + value}`;
      }
    },
    setNumber(number) {
      this.number = number;
    },
    setCallToHistoy(currentPhone) {
      // console.log(currentPhone);

      let contactList = localStorage.getItem(RecentContactsKey);
      if (contactList) {
        contactList = JSON.parse(contactList);
      } else {
        contactList = [];
      }

      const newList = [currentPhone].concat(contactList).slice(0, 20);
      localStorage.setItem(RecentContactsKey, JSON.stringify(newList));
    },

    hangup() {
      // if (this.activeCall && this.device) {
      //   }
      if (this.activeCall) return this.activeCall.disconnect();

      return this.device.disconnect();
    },

    muteCall() {
      this.activeCall.mute(true);
      this.show.mic = true;
      console.log("mudo");
    },

    somCall() {
      this.activeCall.mute(false);
      this.show.mic = false;
      console.log("som");
    },

    clearNumber() {
      if (this.activeCall) return;
      this.number = this.number.substring(0, this.number.length - 1);
    },
    handlePaste() {
      navigator.clipboard.readText().then((clipText) => {
        this.number = clipText;
      });
    },

    onSpace() {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && e.key == "Alt") {
          this.setCall(true);
          this.handlePaste();
        }
      });
    },

    setShowComponentSaveCallHistory(value) {
      this.showComponentSaveCallHistory = value;
    },
    getNumbersTwilio() {
      TwilioService.getNumbersTwilio().then((res) => {
        // console.log(res.data);
        this.options = res.data;
      });
    },
  },

  mounted() {
    this.getToken();
    this.onSpace();
    this.getNumbersTwilio();

    const numberFrom = JSON.parse(localStorage.getItem("numberFrom"));
    // console.log(JSON.parse(numberFrom) );
    // console.log(numberFrom);
    if (numberFrom) {
      this.numberFrom = numberFrom;
    }
  },

  created() {
    // console.log(this.phone);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.onSpace);
  },

  watch: {
    call() {
      // console.log(this.phone);
      if (this.phone === "") {
        return;
      } else {
        if (
          (this.phone.numero[0] == 5 && this.phone.numero[1] == 5) ||
          (this.phone.numero[0] == "+" &&
            this.phone.numero[1] == 5 &&
            this.phone.numero[2] == 5)
        ) {
          return (this.number = this.phone.numero);
        }

        // console.log(this.phone);
        return (this.number = "55" + this.phone.numero);
      }

      this.number = this.phone.numero;
    },

    phone() {
      if (this.phone.numero) {
        this.makeToast("success");
      }
    },

    numberFrom() {
      // console.log(this.numberFrom);
      localStorage.setItem("numberFrom", JSON.stringify(this.numberFrom));
    },
  },

  computed: {
    ...mapState(["call", "phone", "projetoSelecionado"]),

    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  filters: {
    hoursFilter(value) {
      if (!value) {
        return "";
      }

      let hours = Math.floor(value / 60);
      let minutes = value % 60;
      return hours + ":" + minutes;
    },
  },
};
</script>

<style scoped>
label {
  text-align: left;
  font-family: Helvetica, sans-serif;
  font-size: 1.25em;
  color: #777776;
  display: block;
}

div#controls {
  padding: 3em;
  max-width: 50%;
  margin: 0 auto;

  position: fixed;
  top: 10%;
  right: 30%;
  background: black;
  background: #272726;
}

div#controls div {
  float: left;
}

div#controls div#call-controls,
div#controls div#info {
  width: 16em;
  margin: 0 1.5em;
  text-align: center;
}
div#controls div#info div#output-selection {
  /* display: none; */
}

div#controls div#info a {
  font-size: 1.1em;
  color: khaki;
  text-decoration: underline;
  cursor: pointer;
}

div#controls div#info select {
  width: 300px;
  height: 60px;
  margin-bottom: 2em;
}

div#controls div#info label {
  width: 300px;
}

div#controls div#call-controls div#volume-indicators {
  /* display: none; */
  padding: 10px;
  margin-top: 20px;
  width: 500px;
  text-align: left;
}

div#controls div#call-controls div#volume-indicators > div {
  display: block;
  height: 20px;
  width: 0;
}

div#controls p.instructions {
  text-align: left;
  margin-bottom: 1em;
  font-family: Helvetica-LightOblique, Helvetica, sans-serif;
  font-style: oblique;
  font-size: 1.25em;
  color: #777776;
}

div#controls div#info #client-name {
  text-align: left;
  margin-bottom: 1em;
  font-family: "Helvetica Light", Helvetica, sans-serif;
  font-size: 1.25em;
  color: #777776;
}

div#controls button {
  width: 15em;
  height: 2.5em;
  margin-top: 1.75em;
  border-radius: 1em;
  font-family: "Helvetica Light", Helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: lighter;
  outline: 0;
}

div#controls button:active {
  position: relative;
  top: 1px;
}

div#controls div#call-controls {
  /* display: none; */
}

div#controls div#call-controls input {
  font-family: Helvetica-LightOblique, Helvetica, sans-serif;
  font-style: oblique;
  font-size: 1em;
  width: 100%;
  height: 2.5em;
  padding: 0.5em;
  display: block;
}

div#controls div#call-controls button {
  color: #fff;
  background: 0 0;
  border: 1px solid #686865;
}

div#controls div#call-controls button#button-hangup {
  display: none;
}

div#controls #log {
  border: 1px solid #686865;
  width: 35%;
  height: 9.5em;
  margin-top: 2.75em;
  text-align: left;
  padding: 1.5em;
  /* float: right; */
  overflow-y: scroll;

  /* position: absolute; */
  background: black;
  width: 100%;
}

div#controls div#log p {
  color: #686865;
  font-family: "Share Tech Mono", "Courier New", Courier, fixed-width;
  font-size: 1.25em;
  line-height: 1.25em;
  margin-left: 1em;
  text-indent: -1.25em;
  width: 90%;
}
</style>

<style scoped lang="scss">
.form-control:disabled {
  background: transparent;
  cursor: not-allowed;
}
.container {
  position: fixed;
  z-index: 1;
  bottom: 0;
  max-width: 450px;
  left: -5px;
  transition: 0.2s;
  z-index: 10;
}
.top {
  /* position: absolute;
  top: -24px; */
  width: 100%;
  border: 1px solid #ffffff21;
  background: #191c24;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 0 5px;

  button {
    &:hover {
      background: rgba(255, 255, 255, 0.217);
    }
  }
}

.hide {
  bottom: -200px;
  bottom: -100%;
  /* bottom: -70%; */
}

.twilio__alert {
  position: fixed;
  top: 0;
  right: 0;
}

.dialpad {
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  .dialpad__area {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% / 3);
    width: 249px;
    width: 300px;

    width: 228px;

    /* justify-content: center; */

    > div {
      padding: 3px;
      width: calc(100% / 3);
    }
  }

  button {
    width: 80px;
    width: 70px;
    width: 100%;
  }
}

.mic {
  position: relative;

  > p {
    width: 100%;
    width: 200px;
    position: absolute;
    right: 0;
  }
}
</style>



<style lang="scss" scoped>
/* .incoming__component{
    position: absolute;
    left: 100%;
    bottom: 0;
} */
</style>
