<template>
  <div class="save_call_history card">
    <div class="grid-margin stretch-card">
      <div class="card">
        <button class="btn save_call_history_btn_close" @click="closeModal">
          X
        </button>
        <div class="card-body">
          <h4 class="card-title text-primary">Registrar Atividade</h4>

          <!-- <span class="text-white">Autor: </span> <br />
          <span class="text-primary">{{ currentUser.nome }}</span> <br /> -->


          <span class="text-white">Projeto: </span> <br />
          <span class="text-primary"> {{ payloadFromCall.projeto }}</span>


          <br />

          <span class="text-white">Contato: </span> <br />
          <span class="text-primary">{{payloadFromCall.contato_destino.nome}} <span class="text-white" v-if="payloadFromCall.contato_destino.empresa"> - {{payloadFromCall.contato_destino.empresa.nome_fantasia || "" }}</span> </span>


          <br />
          






          <span class="text-white">Telefone: </span> <br />
          <span class="text-primary">{{ payloadFromCall.twilio_to }}</span>
          <br />

          <br />

          <form class="forms-sample" @submit.prevent="saveAtividade">
            <b-form-select
              v-model="selected"
              :options="options"
              size="sm"
              class="mb-3"
            >
            </b-form-select>

            <b-form-group label="Notas" label-for="input10">
              <b-form-textarea
                id="input10"
                placeholder="Notas da chamada"
                :rows="3"
                :max-rows="6"
                v-model="note"
              ></b-form-textarea>
            </b-form-group>

            <div class="d-flex">
              <b-button
                type="submit"
                variant="success"
                class="mr-2"
                :disabled="loading"
              >
                <template v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  Processando...
                </template>
                <template v-else> Salvar </template>
              </b-button>
              <!-- <b-button variant="dark">Cancel</b-button> -->
            </div>
          </form>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// import LeadAtividadesService from '@/services/leadatividades.service';
import LeadAtividadesService from "@/services/leadatividades.service";

export default {
  props: ["payloadFromCall", "setShowComponentSaveCallHistory"],

  data() {
    return {
      note: "",
      // selected: null,
      selected: null,

      options: [
        { value: null, text: "Status da chamada", disabled: true },
        { value: 1, text: "Completou" },
        { value: 2, text: "Telefone inválido" },
        { value: 3, text: "Não atende" },
        { value: 4, text: "Ocupado" },
        { value: 5, text: "Não responde" },
      ],

      loading: false,
    };
  },

  methods: {
    ...mapMutations(["setCall", "setPhone"]),

    async saveAtividade() {
      this.loading = true;

      const data = {
        lead_id: this.payloadFromCall.lead_id,
        notas: this.note,
        contato_telefone_id: this.payloadFromCall.contato_telefone_id,
        twilio_sid: this.payloadFromCall.twilio_sid,
        twilio_duration: this.payloadFromCall.twilio_duration,
        // twilio_status: this.selected,
        twilio_from: "",
        twilio_to: this.payloadFromCall.twilio_to,
        twilio_start_time: this.payloadFromCall.twilio_start_time,
        twilio_end_time: this.payloadFromCall.twilio_end_time,
        chamada_status_id: this.selected,
      };

      try {
        await LeadAtividadesService.save(data);
        // console.log(res);
        this.loading = false;
        //fecha modal
        this.closeModal();
      } catch (error) {
        this.loading = false;

        console.error(error);
      }
    },

    closeModal() {
      this.setShowComponentSaveCallHistory(false);

      this.setCall(false);

      this.setPhone({
        numero: "",
        lead_id: undefined,
        contato_telefone_id: undefined,
      });
    },
  },

  mounted() {

    // console.log(this.payloadFromCall.contato_destino.nome);
  
  },

  computed: {
    ...mapState(["call", "phone"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    // phone() {
    //   console.log(this.phone);
    // },
  },
};
</script>

<style scoped lang="scss">
.save_call_history {
  /* background: red; */
  position: absolute;
  left: 100%;
  bottom: 20%;
  /* height: 100%; */
  width: 100%;
  /* box-shadow: 0 0 35px -3px #000; */
  box-shadow: 0 0 184px -3px #000;

  .save_call_history_btn_close {
    position: absolute;
    right: 0px;
    top: 5px;
  }
}
</style>
