import Vue from 'vue'
const state = Vue.observable({
    showIncomingCall: false,
    isOnlineIncomingCall: false,

})


export const showIncomingCall = () => state.showIncomingCall;
export function setShowIncomingCall(value) {
    state.showIncomingCall = value ?? !state.showIncomingCall
}




export const isOnlineIncomingCall = () => state.isOnlineIncomingCall;
export function setIsOnlineIncomingCall(value) {
    state.isOnlineIncomingCall = value
}



