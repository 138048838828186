<template>
  <div></div>
</template>

<script>
import {
  getTokenConversation,
  conversationsClient,
  newMessage,
  newConversation,
  activeConversation,
  activedSound,
  setActiveSound,
} from "../../store/module-observable/conversation";




import webWhatsappAudio from "../../assets/audio/web_whatsapp.mp3";
import webWhatsappAudioInside from "../../assets/audio/whatsapp_incoming_inside.mp3";
const AUDIO_NEW_MESSAGE = new Audio(webWhatsappAudio)
const AUDIO_WHATSAPP_INSIDE = new Audio(webWhatsappAudioInside)

const ROUTER_NAME = "Conversations";


export default {
  data() {
    return {
    //   activedSound: true,
      usernameChat: undefined,
    };
  },

  mounted() {
    this.getTokenConversation();
    // const isActiveSound = localStorage.getItem("activedSound");
    // this.activedSound = JSON.parse(isActiveSound);

    const usernameChat = localStorage.getItem("usernameChat");
    // console.log(usernameChat);

    this.usernameChat = usernameChat;






    const isActiveSound = localStorage.getItem("activedSound");
    if (isActiveSound) {
    //   this.activedSound = JSON.parse(isActiveSound);
      this.setActiveSound(JSON.parse(isActiveSound))
    }





    // console.log(this.$route.name === ROUTER_NAME);  
  },
  methods: {
    getTokenConversation,
    setActiveSound,

    makeToast(variant = null, newMessage) {
      this.$bvToast.toast(newMessage.body || "Mensagem de mídia", {
        title: `Nova mensagem | ${newMessage.author}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-left",
      });
    },
  },

  computed: {
    conversationsClient,
    newMessage,
    newConversation,
    activeConversation,
    activedSound,
  },
  watch: {
    newMessage(newMessage) {
       

      if (newMessage.author == this.usernameChat ) return;
      if(newMessage?.conversation.sid === this.activeConversation?.sid && this.activedSound) return AUDIO_WHATSAPP_INSIDE.play()
      if(this.$route.name === ROUTER_NAME && this.activedSound) return AUDIO_NEW_MESSAGE.play()

        if(this.$route.name != ROUTER_NAME){

            this.makeToast("primary", newMessage);
        }
      if (!this.activedSound) return;
      AUDIO_NEW_MESSAGE.play();
    },









    newConversation(newConversation) {
      this.$bvToast.toast(newConversation.friendlyName || "", {
        title: `Nova Conversa adicionada`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-left",
      });

      if (!this.activedSound) return;

      AUDIO_NEW_MESSAGE.play();
    },


    activedSound(){
        // console.log(this.activedSound);
    }
    
  },

  beforeDestroy() {
    this.conversationsClient?.removeAllListeners();
  },
};
</script>

<style>
</style>