<template>
  <div>
    <header class="p-3 d-flex justify-content-between">
      <h5 class="m-0" v-if="conversationProxy">
        {{ conversationProxy.friendlyName || conversationProxy.sid }}
      </h5>

      <div>
        <span class="text-muted"
          >{{ conversationProxy.participants.size }} Participantes
        </span>

        <b-dropdown
          size="sm"
          dropleft
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <i data-v-14bb84ae="" class="mdi mdi-dots-vertical"></i>
          </template>
          <b-dropdown-item @click="handleModal">
            <i class="mdi mdi-lead-pencil"></i> Editar nome da
            conversa</b-dropdown-item
          >
          <b-dropdown-item @click="deleteConversation(conversationProxy)"
            ><i class="mdi mdi-delete text-danger"></i> Encerrar
            conversa</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </header>

    <b-modal title="Editar conversa" centered hide-footer v-model="modalShow" @shown="focusMyElement">
      <div class="form-group">
        <label for="input5" class="d-block">Nome da conversa</label>
        <div>
          <input
            type="text"
            placeholder="Nome da conversa"
            class="form-control"
            v-model.trim="name"
            v-on:keyup.enter="updateConversationName"
            ref="focusThis"
          />
        </div>

        <div class="text-right mt-2">
          <button class="btn btn-secondary mr-2" @click="modalShow = false">
            Cancelar
          </button>
          <button class="btn btn-primary" @click="updateConversationName">
            Salvar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["conversationProxy", "resetSelectedConversation"],

  data() {
    return {
      modalShow: false,
      name: "",
    };
  },

  mounted() {
    // console.log(this.conversationProxy);

    

    
  },

  methods: {
    deleteConversation(conversationProxy) {
      if (
        window.confirm(
          "Deseja encerrar a conversa com esse contato? " +
            conversationProxy.friendlyName || conversationProxy.sid
        )
      ) {
        conversationProxy
          .delete()
          .then(() => {
            console.log("Conversa encerrada");
            this.resetSelectedConversation();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    async updateConversationName() {
      if (!this.name || this.name.length < 4)
        return alert("Mínimo 4 Caracteres");

      try {
        await this.conversationProxy.updateFriendlyName(this.name);
        // console.log(res);
        this.modalShow = false;
        this.name = "";
      } catch (error) {
        console.log(error);
      }
    },

    handleModal() {
      this.modalShow = true;
      this.name = this.conversationProxy?.friendlyName || "";
    },

    focusMyElement() {
      this.$refs.focusThis.focus()
    }
  },
};
</script>

<style>
</style>