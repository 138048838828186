<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar p-0 header" toggleable="lg">
    <div class="horizontal-menu">
      <nav class="navbar top-navbar col-lg-12 col-12 p-0">
        <div class="container">
          <div class="
              text-center
              navbar-brand-wrapper
              d-flex
              align-items-center
              justify-content-center
            ">
            <router-link class="navbar-brand brand-logo" to="/">
              <img v-if="isDoktor" src="@/assets/images/health-health.png" alt="logo"
                style="width: 149px; height: 48px; margin-right: 66px" />
              <img v-else src="@/assets/images/piattino-logo.png" alt="logo" style="margin-right: 57px; padding: 5px"
                :class="!this.$store.state.showWhiteMode ? '' : ''" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
              <img v-if="isDoktor" src="@/assets/images/piattino-mini-health.png" alt="logo" />
              <img v-else src="@/assets/images/piattino-mini.png" alt="logo" />
            </router-link>
          </div>

          <div class="
              navbar-menu-wrapper
              d-flex
              align-items-center
              justify-content-end
            " style="flex-grow: 0">
            <div class="select__project hover__items--menu p-3"
              v-if="$store.getters['auth/hasPermission']('Ver Projetos')">
              <a v-b-modal.chooseProject href="#" v-if="projetoSelecionado" class="select__project text-white">{{
                projetoSelecionado.nome }}</a>
              <a href="#" v-b-modal.chooseProject v-else class="select__project text-white">Selecionar projeto</a>

              <!-- {{this.$store.state.projetoSelecionado.nome}} -->
            </div>

            <ChatContainer v-if="$store.getters['auth/hasPermission']('Importar Base') && false
                " />

            <div class="position-relative" v-if="$store.getters['auth/hasPermission']('Ver Projetos')">
              <router-link class="navbar-brand brand-logo ml-2" to="/conversations">
                <i class="fa fa-wechat text-white"></i>
              </router-link>

              <span class="text-success indicator__count" v-if="conversations.length > 0">{{ conversations.length
                }}</span>
            </div>

            <AgentsOnlineList v-if="$store.getters['auth/hasPermission']('Importar Base')" />

            <!-- <div class="border-left ml-3 mr-3" style="height: 36px" v-if="$store.getters['auth/hasPermission']('Importar Base')" ></div> -->

            <!-- <div data-v-6713abfe="" class="border-left ml-3 mr-3" style="height: 36px;"></div> -->

            <!-- <StatusOperador   /> -->

            <!-- <div class="border-left ml-3" style="height: 36px" v-if="$store.getters['auth/hasPermission']('Importar Base')" ></div> -->

            <!-- <div class="border-left ml-3" style="height: 36px"></div> -->

            <!-- <b-form-checkbox
              switch
              size="sm"
              v-model="checked"
              class="mr-4"
              id="theme"
            >
              <span id="tooltip-button-show-event">Dark Theme</span>
            </b-form-checkbox> -->

            <!-- </div> -->

            <!-- title="Mudar para Light mode" -->

            <div class="hover__items--menu">
              <button class="btn" v-if="!checked" v-on:click="setTheme(true)" id="theme" v-b-tooltip.top
                v-b-tooltip.hover="{
                variant: 'warning',
              }" >
                <i class="mdi mdi-white-balance-sunny"></i>
              </button>

              <button class="btn" v-else v-on:click="setTheme(false)" id="theme" v-b-tooltip.top v-b-tooltip.hover="{
                variant: 'warning',
              }" >
                <i class="fa fa-moon-o"></i>
              </button>
            </div>

            <b-navbar-nav class="navbar-nav-right d-none">
              <b-nav-item-dropdown right class="preview-list nav-profile">
                <template slot="button-content">
                  <div class="navbar-profile">
                    <div class="img-xs rounded-circle avatar">
                      {{ initialNameAvatar }}
                    </div>
                  </div>
                </template>
                <h6 class="pl-3 pt-2 mb-0">Perfil</h6>
                <h6 class="pl-3 pt-2 mb-3">{{ currentUser ? currentUser.nome : "" }}</h6>
                <b-dropdown-item class="preview-item" :to="{ name: 'Perfil' }">
                  <div class="preview-thumbnail">
                    <div class="">
                      <i class="icon-settings"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject ellipsis mb-0">Configurações</p>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item class="preview-item" @click.prevent="logOut">
                  <div class="preview-thumbnail">
                    <div class="">
                      <i class="mdi mdi-logout"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject ellipsis mb-0">Sair</p>
                  </div>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
            <button class="navbar-toggler navbar-toggler-right align-self-center" type="button"
              @click="toggleMobileSidebar()">
              <span class="mdi mdi-menu"></span>
            </button>
          </div>
        </div>
      </nav>
      <nav class="bottom-navbar">
        <div class="container">
          <ul class="nav page-navigation">
            <!-- Menu Administração -->
            <li v-if="isAdmin" class="nav-item menu-items">
              <a href="javascript:void(0);" class="nav-link" :class="{
                active: subIsActive([
                  '/clientes',
                  '/users',
                  '/painel-chamadas',
                ]),
              }">
                <i class="mdi mdi-settings mr-2"></i>
                <span class="menu-title">Administração</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu">
                <ul class="submenu-item">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Clientes' }">Clientes</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Users' }">Usuários</router-link>
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'PainelChamadas' }">Painel de Chamadas</router-link>
                  </li>
                </ul>
              </div>
            </li>

            <!-- Menu Projetos -->
            <li v-if="$store.getters['auth/hasPermission']('Ver Projetos')" class="nav-item menu-items">
              <router-link class="nav-link" :to="{ name: 'Projetos' }">
                <i class="mdi mdi-library-books mr-2"></i>
                <span class="menu-title">Projetos</span>
              </router-link>
            </li>

            <!-- Menu WhatsApp -->
            <!-- <li
              v-if="$store.getters['auth/hasPermission']('Ver Projetos')"
              class="nav-item menu-items"
            >
              <router-link class="nav-link" :to="{ name: 'Whatsapp' }">
                <i class="mdi mdi-whatsapp menu-icon text-success"></i>
                <span class="menu-title">WhatsApp</span>
              </router-link>
            </li> -->

            <!-- Menu Qualificação -->
            <li v-if="$store.getters['auth/hasPermission']('Ver Projetos')" class="nav-item menu-items">
              <router-link class="nav-link" :to="{ name: 'Leads' }">
                <i class="mdi mdi-phone mr-2"></i>
                <span class="menu-title">Qualificação</span>
              </router-link>
            </li>

            <!-- Menu Empresas -->
            <li v-if="$store.getters['auth/hasPermission']('Ver Empresas')" class="nav-item menu-items">
              <router-link class="nav-link" :to="{ name: 'Empresas' }">
                <i class="mdi mdi-factory mr-2"></i>
                <span class="menu-title">Empresas</span>
              </router-link>
            </li>

            <!-- Menu Contatos -->
            <li v-if="$store.getters['auth/hasPermission']('Ver Contatos')" class="nav-item menu-items">
              <router-link class="nav-link" :to="{ name: 'Contatos' }">
                <i class="mdi mdi-account-search mr-2"></i>
                <span class="menu-title">Contatos</span>
              </router-link>
            </li>

            <!-- Menu Doktor Interface de Busca -->
            <li v-if="$store.getters['auth/hasPermission']('Ver Medicos')" class="nav-item menu-items">
              <a href="javascript:void(0);" class="nav-link" :class="{
                active: subIsActive([
                  '/doktor/medicos',
                  '/doktor/medicos/pesquisa',
                ]),
              }">
                <i class="fa fa-user-md mr-2"></i>
                <span class="menu-title">Médicos</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu">
                <ul class="submenu-item">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'MedicoList' }">
                      <i class="fa fa-user-md mr-2"></i>
                      <span class="menu-title">Pesquisa</span>
                      <!-- Interface de Busca -->
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'MedicoSearch' }">
                      <i class="fa fa-filter mr-2"></i>
                      <span class="menu-title">Por Região</span>
                      <!-- Interface de Busca -->
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <!-- <li class="nav-item menu-items">
              <router-link class="nav-link" to="/dashboard">
                <i class="mdi mdi-speedometer menu-icon"></i>
                <span class="menu-title">Dashboard</span>
              </router-link>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>

    <ChooseProject v-if="$store.getters['auth/hasPermission']('Ver Projetos')" />
  </b-navbar>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ChooseProject from "@/components/header/ChooseProject.vue";
// import StatusOperador from "@/components/status-operador/StatusOperador.vue";
// import StatusOperador from "../../components/Status";
import { getItem, setItem } from "@/hooks/useLocalStorage";
import AgentsOnlineList from "../../components/agents-online/AgentsOnlineList.vue";

import ChatContainer from "@/components/chat/ChatContainer.vue";
import { conversations } from "../../store/module-observable/conversation";

export default {
  components: {
    ChooseProject,
    // StatusOperador,
    AgentsOnlineList,
    ChatContainer,
  },
  name: "app-header",
  data() {
    return {
      checked: this.$store.state.showWhiteMode,
    };
  },
  mounted() {
    var navItems = document.querySelectorAll(
      ".horizontal-menu .page-navigation >.nav-item"
    );
    document
      .querySelectorAll(".horizontal-menu .page-navigation >.nav-item")
      .forEach(function (el) {
        el.addEventListener("click", function () {
          for (var i = 0; i < navItems.length; i++) {
            if (navItems[i] == el) {
              el.classList.toggle("show-submenu");
            } else {
              navItems[i].classList.remove("show-submenu");
            }
          }
        });
      });

    this.setThemeInit();
    this.setColorScroll();
    this.setProject();

    // this.apresentation();
  },
  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),
    toggleMobileSidebar: () => {
      document
        .querySelector(".bottom-navbar")
        .classList.toggle("header-toggled");
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    handleSCroll() {
      let header = document.querySelector("body");
      if (window.scrollY > 70) {
        header.classList.add("fixed-on-scroll");
      } else {
        header.classList.remove("fixed-on-scroll");
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    setColorScroll() {
      let el = document.querySelector("body");

      if (this.$store.state.showWhiteMode) {
        el.classList.add("white__scroll");
      } else {
        el.classList.remove("white__scroll");
      }
    },
    setThemeInit() {
      //setThemeInit para a primeira vez logado
      // if true coloca tema branco, else coloca tema dark
      let isWhite = JSON.parse(localStorage.getItem("WHITEMODE"));
      // console.log("setThemeInit ", isWhite);

      if (isWhite === null) {
        // alert()
        isWhite = true;
      }

      this.$store.dispatch("setWhiteAndDarkModeAction", isWhite);
      this.checked = isWhite;

      if (this.showWhiteMode) {
        document.body.classList.add("white--mode");
      }
    },
    setProject() {
      //  let selectedProject = JSON.parse(localStorage.getItem("selected-project"))
      let selectedProject = getItem("selected-project");
      if (!selectedProject) return;

      this.atualizarProjetoSelecionadoAction(selectedProject);

      // console.log("/*", selectedProject);
    },
    // apresentation => apresenta o modo dark para o usuario
    apresentation() {
      const isFirstTime = getItem("first-time");

      if (!isFirstTime) {
        setItem("first-time", true);
        setTimeout(() => {
          document.getElementById("theme").click();
        }, 2000);

        setTimeout(() => {
          document.getElementById("theme").click();
        }, 5000);
      }
    },
    setTheme(isWhite) {
      //isWhite: bolean
      this.$store.dispatch("setWhiteAndDarkModeAction", isWhite);
      this.checked = isWhite;
    },
  },
  created() {
    // window.addEventListener("scroll", this.handleSCroll);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleSCroll);
  },
  watch: {
    $route() {
      document
        .querySelector(".bottom-navbar")
        .classList.remove("header-toggled");
    },

    checked() {
      this.$store.dispatch("setWhiteAndDarkModeAction", this.checked);
      this.setColorScroll();
    },

    showWhiteMode() {
      if (this.showWhiteMode) {
        document.body.classList.add("white--mode");
      } else {
        document.body.classList.remove("white--mode");
      }
    },
  },

  computed: {
    ...mapState(["projetoSelecionado", "showWhiteMode"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      return this.$store.getters["auth/hasRole"]("admin");
    },
    isDoktor() {
      return this.$store.getters["auth/hasRole"]("cliente-doktor");
    },
    initialNameAvatar() {
      if (!this.currentUser) return null;
      const fullName = this.currentUser.nome.split(" ");
      const firstLetter = fullName[0][0] || "";
      let secondLetter;
      if (fullName[1]) {
        secondLetter = fullName[1][0];
      } else {
        secondLetter = "";
      }

      return `${firstLetter}${secondLetter}`;
    },
    conversations,
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1366px;
  max-width: 1980px;
}

.header {
  z-index: 2;
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 40px;
}

.select__project {
  /* margin-right: 50px; */
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;

  a {
    text-decoration: underline;
  }

  .select__project__rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #2c2e33;

    /* -------------- */

    box-sizing: border-box;
    width: auto;
    appearance: none;
    background: none rgba(255, 255, 255, 0.1);
    /* display: inline-block; */
    border: none;
    outline: none;
    transition: background-color 100ms ease-in 0s, box-shadow 100ms ease-in 0s,
      color 100ms ease-in 0s;
    font-weight: 600;
    text-decoration: none;
    margin: 0px;
    color: rgb(255, 255, 255);
    box-shadow: #ffffff80 0px 0px 0px 1px;
    padding: 0.5rem;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      color: rgb(255, 255, 255);
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.2);
      box-shadow: #ffffff 0px 0px 0px 1px;
    }
  }
}

#theme i {
  padding: 5px;
  border-radius: 50%;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    background: rgba(92, 88, 88, 0.475);
  }
}

.avatar {
  background: #65aadd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;

  font-family: SegoeUI-Regular-final, Segoe UI, "Segoe UI Web (West European)",
    Segoe, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma,
    Helvetica, Arial, sans-serif;
}

.indicator__count {
  position: absolute;
  bottom: 16px;
  font-size: 10px;
  right: 8px;
}
</style>

<style lang="scss">
.custom-control-label {
  cursor: pointer;
}

#template-header {
  position: fixed;
  width: 100%;
}
</style>
