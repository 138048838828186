<template>
  <div class="hover__items--menu">
    <button class="btn position-relative" @click="handleShowAgents"  title="Usuários Logados">
        <i class="icon-people"></i>
        <span
          class="status__cicle"
          :class="status === 'Disponível' ? 'bg-success' : 'bg-secondary'"
        ></span>
    </button>

    <div class="col-md-4 grid-margin stretch-card agentes_online" v-if="show">
      <div class="card" style="width: 470px">
        <button
          class="btn btn-link agentes_online__button_close"
          @click="handleShowAgents"
        >
          <h4>x</h4>
        </button>

        <div class="card-body" style="overflow: auto">
          <!-- <h1>Ola mundo</h1> -->

          <h4 class="card-title">
            Usuários logados
            <span class="text-muted ml-2" style="font-size: 13px">{{
              usuarios.length
            }}</span>
          </h4>
          <StatusOperador :setStatus="setStatus" />

          <div class="template-demo">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th class="pl-0 col-1">Nome</th>
                  <th class="text-right12" style="white-space: normal">
                    Tempo do status
                  </th>
                  <th class="text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                <!-- v-if="currentUser.id" -->
                <tr v-for="(item, index) in usuarios" :key="index">
                  <td
                    class="pl-0"
                    :class="currentUser.id == item.id ? 'strong__text' : ''"
                  >
                   <span class="name name__strong">{{ item.user }}</span>  
                    <span
                      v-if="currentUser.id == item.id"
                      class="badge badge-outline-success ml-3"
                      >Você</span
                    >
                  </td>

                  <td class="pr-0">
                    <span
                      class="mr-2"
                      :class="
                        item.status == 'Disponível'
                          ? 'text-success'
                          : item.status == 'Indisponível'
                          ? 'text-danger'
                          : 'text-warning'
                      "
                      >{{ item.count | formatForMinutes }}
                    </span>
                  </td>

                  <td class="pr-0 text-right">
                    <strong
                      :class="
                        item.status == 'Disponível'
                          ? 'text-success'
                          : item.status == 'Indisponível'
                          ? 'text-danger'
                          : 'text-warning'
                      "
                      >{{ item.status }}</strong
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="usuarios.length === 0" class="text-center mt-5">
              <span class="text-muted">Não há usuarios logados no sistema</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-alert
      v-model="showDismissibleAlert"
      :variant="
        msgAlert.status == 'Disponível'
          ? 'success'
          : msgAlert.status == 'Indisponível'
          ? 'danger'
          : 'info'
      "
      dismissible
      class="alert"
    >
      {{ `${msgAlert.user} - ${msgAlert.status}` }}
    </b-alert>
  </div>
</template>

<script>
import SocketioService from "../../services/socketio.service";

import StatusOperador from "../status-operador/StatusOperador.vue";

export default {
  components: {
    StatusOperador,
  },

  data() {
    return {
      show: false,
      usuarios: [],
      showDismissibleAlert: false,
      msgAlert: {
        user: "",
        status: "",
      },
      ping: undefined,
      status: undefined,
    };
  },
  methods: {
    handleShowAgents() {
      this.show = !this.show;
    },

    listeners() {
      SocketioService.socket.on("notify-changed-status", (data) => {
        if (data.currentUser) {
          this.showDismissibleAlert = true;

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 3000);

          this.msgAlert = {
            status: data.currentUser.status,
            user: data.currentUser.usuario,
          };
        }
      });

      SocketioService.socket.on("update-time", (data) => {
        // update-time eh chamada a cada 1 segundo
        this.usuarios = data.list;
      });
    },

    handlePing() {
      // handlePing da ping no server heroku para não hibernar

      this.ping = setInterval(() => {
        SocketioService.ping()
          .then(() => {
            console.log("ping");

            // console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }, 600000);
      //10 minutos
    },
    setStatus(value) {
      this.status = value;
    },
  },

  mounted() {
    this.listeners();
    this.handlePing();

    const data = localStorage.getItem("status-operador") || undefined;
    this.setStatus(data);
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      return this.$store.getters["auth/hasRole"]("admin");
    },
  },

  created() {
    SocketioService.setupSocketConnection();
  },
  // beforeDestroy() {
  //   SocketioService.disconnect();
  // },

  beforeDestroy() {
    // console.log("beforeDestroy");
    clearInterval(this.ping);
    SocketioService.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.agentes_online {
  position: fixed;
  /* background: #2a3038; */
  /* width: 20%; */
  /* max-width: 360px;
    max-width: 20%; */
  /* left: 0;
  top: 0; */
  min-width: 360px;
  max-width: max-content;
  height: 100%;
  z-index: 100;
  /* overflow: auto; */
  bottom: 0;
  top: 0;
  right: 0;
  padding: 0;
  box-shadow: 0 0 35px -3px #000;

  .agentes_online__button_close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  }
}
.alert {
  position: fixed;
  top: 0px;
  left: 0px;
}
.status__cicle {
  position: absolute;
  border-radius: 50%;
  width: 7px;
  height: 7px;
}
.name{
  font-weight: 600;
}
.strong__text{
  color: white;
}
</style>
