import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from './store'
import axios from 'axios'
import "./directives"
import "./filters"
import VCalendar from 'v-calendar';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSweetalert2)
Vue.use(VCalendar)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false

var vm = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

// Handling Expired Token
axios.interceptors.response.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        if (error && store.state.auth.status.loggedIn) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch("auth/logout");
                return router.push("/login");
            } else if (error.response.status === 403 &&
                !originalRequest._retry &&
                error.response.data.reason &&
                error.response.data.reason == 'EmailNotVerified') {
                originalRequest._retry = true;
                return router.push({ name: "EmailVerificar" });
            } else {
                //Tratamento de erros genérico
                let msgErro;
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.data.message) {
                        msgErro = error.response.data.message;
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    msgErro = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    msgErro = error.message;
                }
                vm.$bvToast.toast(msgErro, {
                    title: "Erro!",
                    autoHideDelay: 1000,
                    toaster: "b-toaster-bottom-left",
                    variant: "danger",
                });
                return Promise.race(error);
            }
        }
        return Promise.reject(error);
    })