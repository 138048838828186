<template>
  <div
    class="d-flex"
    :class="message.author === usernameChat ? 'justify-content-end ' : ' '"
  >
    <div
      class="position-relative image"
      :class="message.author === usernameChat ? '' : 'ml-0'"
    >
      <!-- <p>PDF</p> -->

      <div v-if="url" :class="message.author === usernameChat ? 'my__user__name ' : 'another__user__name'" >

        <!-- <embed :src="url" type="application/pdf" width="100%" height="100%" /> -->

        <iframe id="iframe-pdf-shower" :src="url" style="width:100%; height:320px;" frameborder="0" allowfullscreen></iframe>
       
        <!-- <object :src="url" type="application/pdf" title="SamplePdf" width="500" height="720">
          <a href="YourFile.pdf">shree</a> 
      </object> -->

         <a target="_black" class="text-white" :href="url">abrir pdf</a>


        


        
        <!-- <img :src="url" alt="a" class="w-100 rounded"  > -->
        <!-- <b-img :src="url" fluid alt="Fluid image" class="w-100"></b-img> -->
        <!-- <b-img-lazy  :src="getFileUrl()" alt="Image 1" class="w-100"></b-img-lazy> -->
      </div>

      <b-skeleton-img v-else  class="position-relative image"   width="100%" height="200px"></b-skeleton-img>

 
          
         
          

      <span class="conversation__view__hours">{{
        message.state.timestamp.toLocaleString()
      }}</span>
    </div>

    <!-- <h1>{{usernameChat}}</h1> -->
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      usernameChat: undefined,
      url: undefined,
      fileName: ""
    };
  },
  mounted() {
    this.getUserName();
    this.getFileUrl();
  },
  methods: {
    getUserName() {
      this.usernameChat = localStorage.getItem("usernameChat") || "PIATTINO";
    },
    async getFileUrl() {
      // console.log("media ", this.message.media);
      const media = this.message.media;
      const res = await media.getContentTemporaryUrl();



      // console.log(res);
      this.url = res; 

      this.getFileName(media)
    },

    getFileName(media){
      // const name = media.filename
      // console.log(name);
      this.fileName = media.filename

    }
  },
};
</script>

<style lang="scss" scoped>
.image{
  width: 20%;
}
</style>