<template>
  <section class="history__call">
    <div class="history__call__container">
      <button
        class="btn btn-fw btn-link bg-transparent border-0 btn-secondary btn-block text-left"
        v-for="(telefone, index) in historyLis"
        :key="index"
        @click="setNumber(telefone)"
      >
        {{ telefone }}
      </button>
    </div>
  </section>
</template>

<script>
const RecentContactsKey = "RECENT_CONTACTS";

export default {
  props: {
    setNumber: { type: Function },
    activeCall: {},
  },

  data() {
    return {
      historyLis: [],
    };
  },

  mounted() {

    this.setItems();
  },

  methods: {
    async setItems() {
   

      setTimeout(async () => {
        let res = await localStorage.getItem(RecentContactsKey);
        res = await JSON.parse(res);

        // console.log(res);

        this.historyLis = res;
      }, 2000);
    },
  },

  watch: {
    activeCall() {
      this.setItems();
      // console.log("activeCall mudoy");
    },
  },
};
</script>

<style lang="scss" scoped>
.history__call {
  position: absolute;
  right: 0;

  .history__call__container {
    overflow: auto;
    height: 180px;
  }
}
</style>
