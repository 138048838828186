<template>
  <b-modal
    id="modal-whatsapp-new-conversation"
    lazy
    :static="true"
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    content-class="modall"
    hide-footer
    scrollable
    v-model="modalShow"
  >
    <template #modal-title>
      <span>Iniciar Conversa WhatsApp</span> <i class="mdi mdi-whatsapp"></i>
      <br>
      <div v-if="infoProject" class="text-warning">

       <span >{{infoProject.description}}</span> - <span>{{infoProject.telefone}}</span>
      </div>
    </template>

    <form
      @submit.prevent="sendMessageWhatsapp"
      class="d-flex flex-column h-100"
    >
      <!-- <h2 v-for="item in selectedRows " :key="item.id">la {{item.telefone}}</h2> -->

      <!-- <div v-if="contato">
        <h5>
          Nome: <span class="text-primary"> {{ contato.nome }}</span>
        </h5>
        <h5 v-if="selectedTelefone">
          Número selecioado:
          <span class="text-primary"> {{ selectedTelefone.telefone }} </span>
        </h5>

        <div class="d-flex align-items-baseline mb-2">
          <h5>Formato internacional:</h5>

          <TheMask
            v-model="newNumber"
            type="text"
            :mask="['+############', '+#############', '+###############']"
            class="form-control ml-3"
            style="flex: 1"
            required
          />

          <i
            v-b-tooltip.hover
            title="Código do país + DDD + número. exemplo: +5511999999999"
            class="fa fa-question-circle ml-2"
          ></i>
        </div>
      </div> -->

      <div class="form-group">
        <label for="input5" class="d-block">Nome da conversa (opcional)</label>
        <div>
          <input
            type="text"
            placeholder="Dr. John Doe"
            class="form-control w-100"
            v-model.trim="conversationName"
          />
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="input5" class="d-block">whatsapp do projeto</label>
        <div>
          <input
            type="text"
            placeholder="Name"
            class="form-control w-100"
            :value="'+' + selectedNumber"
            
          />
        </div>
      </div> -->

      <div class="form-group">
        <label for="input5" class="d-block">whatsapp do cliente</label>
        <div>
          <!-- <input
            type="text"
            placeholder="+5511991929394"
            class="form-control"
          /> -->

          <TheMask
            v-model="whats_client"
            type="text"
            :mask="['+#####################']"
            class="form-control w-100"
            placeholder="+5511991929394"
            required
          />
        </div>
      </div>

      <div class="form-group">
        <b-form-select v-model="selected" :options="options"></b-form-select>
      </div>

      <div>
        <div class="mt-2" v-if="selected">
          <div class="form-inline">
            <!-- <b-form-select
              id="inline-form-custom-select-pref"
              class="mb-2 mr-sm-2 mb-sm-0 "
              style="min-height: auto"
              
              :value="5"
            ></b-form-select> -->

            <div
              class="mb-1 d-flex w-100"
              v-for="(field, index) in selected.fields"
              :key="index"
            >
              <!-- <h1>{{field}}</h1> -->
              <b-form-input
                style="width: 55px"
                class="mb-2 mr-sm-2 mb-sm-0"
                :value="`{{${field.name}}}`"
                disabled
                required
              ></b-form-input>
              <!-- <h1>{{field}}</h1> -->
              <!-- <input type="text" value="1" readonly> -->

              <b-form-input
                id="inline-form-input-name"
                class="mb-2 mr-sm-2 mb-sm-0  w-100"
                :placeholder="field.placeholder"
                v-model.trim="field.value"
                required
              ></b-form-input>
            </div>
          </div>
        </div>

        <!-- <button class="btn mt-3" @click="setInftemplate">
          <i class="icon-plus"></i>
        </button> -->
      </div>

      <div class="p-3 flex-grow-1">
        <!-- <p v-if="selected" class="text-muted">{{ selected.body }}</p> -->
        <p v-if="selected" class="text-muteds">{{ selectedTemplate }}</p>
      </div>

      <!-- <button class="btn btn-success" @click="teste">set</button> -->

      <div class="text-right">
        <!-- <button class="btn btn-success" @click="sendMessageWhatsapp"> -->
        <button class="btn btn-success" :disabled="!selectedTemplate">
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Enviando...
          </template>
          <template v-else> Enviar </template>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { TheMask } from "vue-the-mask";

import TwilioService from "@/services/twilio.service";
import axios from "axios";

export default {
  props: ["infoProject"],
  components: {
    TheMask,
  },
  data() {
    return {
      modalShow: false,
      selected: null,
      options: [{ value: null, text: "Selecione um template" }],
      selectedTemplate: undefined,
      loading: false,
      whats_client: "",
      conversationName: "",
    };
  },

  mounted() {
    this.getTemplates();
  },
  methods: {
    getTemplates() {
      TwilioService.getTemplatesWhatsApp()
      // axios.get("http://localhost:5555/")

        .then((res) => {
          // console.log(res.data);
          this.options = [...this.options, ...res.data];
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async sendMessageWhatsapp() {
      // console.log(this.whats_client);
      const fields = this.selected.fields;
      const isValid = fields.every((item) => {
        return item.value != "";
      });
      if (!isValid) return;
      // console.log(this.infoProject.telefone);
      if(!this.infoProject.telefone) return


      this.loading = true;
      try {
        // const res = await axios.post(
        await axios.post(
          "https://start-conversation-by-template-5984-dev.twil.io/createWhatsaAppConversation",
          {
            whats_client: this.whats_client,
            // whats_client: "5511971970000",
            // whats_client: "558287086083",
            // whats_twilio: "5511998009970",
            // whats_twilio: "14155238886",
            // whats_twilio: this.selectedNumber,
            whats_twilio: this.infoProject.telefone,
            
            friendly_name: this.conversationName || "WHATSAPP",
            body_message: this.selectedTemplate,
          }
        );

        // console.log(res);

        this.selectedTemplate = undefined;
        this.selected = null;
        this.modalShow = false;
        this.loading = false;
        this.whats_client = "";
      } catch (error) {
        //   console.log("erro aqiu");
        console.warn(error);
        this.loading = false;
      }
    },
   

    changeTemplate() {
      this.selectedTemplate = this.selected?.body;

      const val = this.selected?.fields || [];

      val.forEach((item, index) => {
        // console.log(index + 1);

        // console.log(item.value);
        if (!item.value) return;

        if (item.name == index + 1) {
          this.selectedTemplate = this.selectedTemplate.replace(
            `{{${index + 1}}}`,
            item.value
          );
        }
      });
    },
  },
  computed: {},

  watch: {
    // contatoLead() {
    //   console.log("selectedRows", this.contatoLead);
    //   this.contato = this.contatoLead;
    // },

    selected() {
      this.selectedTemplate = this.selected?.body;
    },

    "selected.fields": {
      handler() {
        this.changeTemplate();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#modal-whatsapp-new-conversation {
  /* max-height: 70vh;
    
    overflow-y: scroll; */

  .modall {
    background: #191c24 !important;
    min-height: 370px;
  }

  .headerModal {
  }
}
</style>


<style >
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}
</style>