import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/auth";

class AuthService {
    login(user) { 
        return axios
            .post(API_URL + '/login', {
                email: user.email,
                password: user.password
            })
            .then(function (response) {
                if (response.data.token) {
                    localStorage.setItem('token', JSON.stringify(response.data.token));
                    response.data.user.permissions = response.data.permissions;
                    response.data.user.roles = response.data.roles;
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                }
                return response.data.user;
            });
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

    }

    register(user) {
        return axios.post(API_URL + '/register', {
            email: user.email,
            password: user.password,
            nome: user.nome,
        });
    }

    emailRequestVerification() {
        return axios.get(API_URL + '/email/request-verification', { headers: authHeader() });
    }

    sendResetLinkEmail(user) {
        return axios.post(API_URL + '/password/reset-request', { email: user.email });
    }

    resetPassword(user, token) {
        return axios.put(API_URL + '/password/reset', {
            email: user.email,
            password: user.password,
            token: token,
        });
    }

    me() {
        return axios.get(API_URL + '/me', { headers: authHeader() })
            .then(function (response) {
                if (response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    updateUser(user) {
        return axios.put(API_URL + '/update',
            { nome: user.nome },
            { headers: authHeader() });
    }

    updatePassword(password) {
        return axios.put(API_URL + '/password',
            { password: password, },
            { headers: authHeader() });
    }
}

export default new AuthService();
