var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hover__items--menu"},[_c('button',{staticClass:"btn position-relative",attrs:{"title":"Usuários Logados"},on:{"click":_vm.handleShowAgents}},[_c('i',{staticClass:"icon-people"}),_c('span',{staticClass:"status__cicle",class:_vm.status === 'Disponível' ? 'bg-success' : 'bg-secondary'})]),(_vm.show)?_c('div',{staticClass:"col-md-4 grid-margin stretch-card agentes_online"},[_c('div',{staticClass:"card",staticStyle:{"width":"470px"}},[_c('button',{staticClass:"btn btn-link agentes_online__button_close",on:{"click":_vm.handleShowAgents}},[_c('h4',[_vm._v("x")])]),_c('div',{staticClass:"card-body",staticStyle:{"overflow":"auto"}},[_c('h4',{staticClass:"card-title"},[_vm._v(" Usuários logados "),_c('span',{staticClass:"text-muted ml-2",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.usuarios.length))])]),_c('StatusOperador',{attrs:{"setStatus":_vm.setStatus}}),_c('div',{staticClass:"template-demo"},[_c('table',{staticClass:"table mb-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.usuarios),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"pl-0",class:_vm.currentUser.id == item.id ? 'strong__text' : ''},[_c('span',{staticClass:"name name__strong"},[_vm._v(_vm._s(item.user))]),(_vm.currentUser.id == item.id)?_c('span',{staticClass:"badge badge-outline-success ml-3"},[_vm._v("Você")]):_vm._e()]),_c('td',{staticClass:"pr-0"},[_c('span',{staticClass:"mr-2",class:item.status == 'Disponível'
                        ? 'text-success'
                        : item.status == 'Indisponível'
                        ? 'text-danger'
                        : 'text-warning'},[_vm._v(_vm._s(_vm._f("formatForMinutes")(item.count))+" ")])]),_c('td',{staticClass:"pr-0 text-right"},[_c('strong',{class:item.status == 'Disponível'
                        ? 'text-success'
                        : item.status == 'Indisponível'
                        ? 'text-danger'
                        : 'text-warning'},[_vm._v(_vm._s(item.status))])])])}),0)]),(_vm.usuarios.length === 0)?_c('div',{staticClass:"text-center mt-5"},[_c('span',{staticClass:"text-muted"},[_vm._v("Não há usuarios logados no sistema")])]):_vm._e()])],1)])]):_vm._e(),_c('b-alert',{staticClass:"alert",attrs:{"variant":_vm.msgAlert.status == 'Disponível'
        ? 'success'
        : _vm.msgAlert.status == 'Indisponível'
        ? 'danger'
        : 'info',"dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(((_vm.msgAlert.user) + " - " + (_vm.msgAlert.status)))+" ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"pl-0 col-1"},[_vm._v("Nome")]),_c('th',{staticClass:"text-right12",staticStyle:{"white-space":"normal"}},[_vm._v(" Tempo do status ")]),_c('th',{staticClass:"text-right"},[_vm._v("Status")])])])}]

export { render, staticRenderFns }