import axios from "axios";
const TWILIO = "https://orchid-woodpecker-7617.twil.io";

const API_TWILIO = process.env.VUE_APP_TWILIO_API_URL;
const SID_TWILIO = process.env.VUE_APP_TWILIO_SID;
const TOKEN_TWILIO = process.env.VUE_APP_TWILIO_TOKEN;

export default {
  getToken(email) {
    return axios.post(
      "https://app-voice-mult-telefones-3214-dev.twil.io/voice-token",
      {
        nome: email
      }
    );
  },

  getTokenConversation(usernameChat) {
    return axios.post("https://orchid-woodpecker-7617.twil.io/token-chat", {    
      identity: usernameChat
    });
  },

  // REQUIRED
  getTemplatesWhatsApp() {
    return axios.get("https://whatsapp-list-templates-8118.twil.io/index");
  },

  getCalls(telefone, dateStart) {
    let start = "";
    if (dateStart != undefined) start = `&StartTime=${dateStart}`;

    // if (telefone[0] != 5 && telefone[1] != 5) telefone = "55" + telefone

    if (telefone[0] == 5 && telefone[1] == 5) {
      telefone = "" + telefone;
    } else {
      telefone = "55" + telefone;
    }

    // console.log(telefone);

    // telefone = "5511971972437"

    return axios.get(
      `${API_TWILIO}/${SID_TWILIO}/Calls.json?To=${telefone}${start}`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  },

  // REQUIRED
  getCallsFromAgents(user, dateStart) {
    return axios.get(
      `${API_TWILIO}/${SID_TWILIO}/Calls.json?PageSize=1000&From=client:${user}&StartTime=${dateStart}T00:00:00+00:00`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  },

  // REQUIRED

  getCallDetalls(params) {
    return axios.get(
      // `${API_TWILIO}/${SID_TWILIO}/Calls.json?PageSize=1000&From=client:${params.user}&StartTime>=${params.dateStart}&EndTime<=${params.dateEnd}`,
      `${API_TWILIO}/${SID_TWILIO}/Calls.json?PageSize=1000&From=client:${params.user}&StartTime>=${params.dateStart}&EndTime<=${params.dateEnd}&${params.status}`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  },

  getCallDetallsTo(params) {
    return axios.get(
      // `${API_TWILIO}/${SID_TWILIO}/Calls.json?PageSize=1000&From=client:${params.user}&StartTime>=${params.dateStart}&EndTime<=${params.dateEnd}`,
      `${API_TWILIO}/${SID_TWILIO}/Calls.json?PageSize=1000&To=client:${params.user}&StartTime>=${params.dateStart}&EndTime<=${params.dateEnd}&${params.status}`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  },


  



  // REQUIRED
  getEventsFromEachCall(sid) {
    return axios.get(`${API_TWILIO}/${SID_TWILIO}/Calls/${sid}/Events.json`, {
      auth: {
        username: SID_TWILIO,
        password: TOKEN_TWILIO
      }
    });
  },

  // REQUIRED

  getRecording(sid) {
    // /2010-04-01/Accounts/AC58e97f9c4caee82c0798e482bfc1f455/Calls/CA45a27f47f22b461a442dd864499a87b9/Recordings.json
    return axios.get(
      `${API_TWILIO}/${SID_TWILIO}/Calls/${sid}/Recordings.json`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  },
  // REQUIRED

  getNumbersTwilio() {
    return axios.get(`${TWILIO}/get-numbers-twilio`, {
      auth: {
        username: SID_TWILIO,
        password: TOKEN_TWILIO
      }
    });
  },

  //deprecated
  getCallRecording(callSid) {
    return axios.get(
      `${API_TWILIO}/${SID_TWILIO}/Recordings.json?CallSid=${callSid}`,
      {
        auth: {
          username: SID_TWILIO,
          password: TOKEN_TWILIO
        }
      }
    );
  }
};
