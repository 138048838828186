<template>
<svg viewBox="0 0 14 18" width="14" height="18" class=""><path fill="currentColor" d="M8.906 10.132h-1.64V7.569c0-.3-.243-.545-.545-.545h-.102c-.3 0-.545.243-.545.545v3.21c0 .3.243.545.545.545h.102l.003-.001.003.001h2.178c.3 0 .545-.243.545-.545v-.102a.544.544 0 0 0-.544-.545zm0-5.732H5.094A3.598 3.598 0 0 0 1.5 7.994v3.812A3.598 3.598 0 0 0 5.094 15.4h3.812a3.598 3.598 0 0 0 3.594-3.594V7.994A3.598 3.598 0 0 0 8.906 4.4zm2.178 7.406a2.178 2.178 0 0 1-2.178 2.178H5.094a2.178 2.178 0 0 1-2.178-2.178V7.994c0-1.203.975-2.178 2.178-2.178h3.812c1.203 0 2.178.975 2.178 2.178v3.812z"></path></svg>
</template>

<script>
export default {

}
</script>

<style>

</style>