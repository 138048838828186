import Vue from 'vue'

Vue.filter('cnpjFilter', function (value) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")

})
Vue.filter('cepFilter', function (value) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2-$3")

})
Vue.filter('formatDate', function (date) {
    if (date) {
        let dateObj = new Date(date + 'T00:00:00')
        return new Intl.DateTimeFormat('pt-BR').format(dateObj);
    }
})
//Formtar datetimes como: 2021-08-26T21:06:14.000000Z
Vue.filter('formatDateTime', function (date) {
    if (date) {
        let dateObj = new Date(date)
        return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).format(dateObj);
    }
})
Vue.filter('matrizFilialFilter', function (matrizFilial) {
    if (matrizFilial) {
        if (matrizFilial == 1) return 'MATRIZ'
        else if (matrizFilial == 2) return 'FILIAL'
    }
})
Vue.filter('simnaoFilter', function (opcao) {
    if (opcao) return 'Sim'
    else return 'Não'
})

Vue.filter('numberFilter', function (number) {
    return new Intl.NumberFormat().format(number)
})

Vue.filter('fileSize', function (bytes) {
    let si=false; 
    let dp=1;
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
})


Vue.filter('formatForMinutes', function (seconds) {

    let s = seconds / 3600    
    return new Date(s * 3600 * 1000).toISOString().substr(11, 8)


})
